import { isNull } from 'lodash';
import { COMMON_ERRORS } from '_constants/errors';
import { formIsValid } from 'utils/validation';
import { validateText } from 'utils/validation/fields';
import { FORM_ACTION_TYPES, FORM_REQUIRED_FIELDS, formInitialState, formNames } from './constants';

export const isBase64 = (content) => {
  return content.includes('base64');
};

export const init = (initialState) => {
  return { ...initialState };
};

export const validateData = (action) => {
  const titleMap = {
    submitted_time: 'submitted time',
    submitted: 'submitted date',
  };
  switch (action.name) {
    case 'submitted': {
      if (isNull(action.payload)) return COMMON_ERRORS.isRequired('submitted date');
      return false;
    }

    default:
      return validateText({
        value: action.payload?.trim(),
        name: titleMap[action.name],
        required: FORM_REQUIRED_FIELDS.includes(action.name),
        min: 4,
      });
  }
};

const checkFormIsValid = (errors) => formIsValid(errors, FORM_REQUIRED_FIELDS);

const validateFormData = (data) =>
  FORM_REQUIRED_FIELDS.reduce((acc, name) => {
    acc[name] = validateData({
      payload: data.attributes[name],
      name,
    });
    return acc;
  }, {});

export const formReducer = (formState, action) => {
  switch (action.type) {
    case FORM_ACTION_TYPES.SET_INITIAL_DATA: {
      const newData = {
        ...formState.data,
        ...action.payload,
      };

      const shadowErrors = validateFormData(newData);

      return {
        ...formState,
        data: newData,
        canBeSubmitted: checkFormIsValid(shadowErrors),
      };
    }

    case FORM_ACTION_TYPES.VALIDATE_DATA: {
      const { data } = formState;

      const newErrors = validateFormData(data);

      return {
        ...formState,
        errors: newErrors,
        canBeSubmitted: checkFormIsValid(newErrors),
      };
    }

    case FORM_ACTION_TYPES.ENTER_DATA: {
      const newData = {
        ...formState.data,
        attributes: {
          ...formState.data.attributes,
          [action.name]: action.payload,
        },
      };

      const newErrors = {
        ...formState.errors,
        [action.name]: false,
      };

      const shadowErrors = validateFormData(newData);

      return {
        ...formState,
        data: newData,
        errors: newErrors,
        canBeSubmitted: checkFormIsValid(shadowErrors),
      };
    }

    case FORM_ACTION_TYPES.SET_YOUTH_DATA: {
      const { payload, id } = action;
      const newData = {
        ...formState.data,
        attributes: {
          ...formState.data.attributes,
          [formNames.youthId]: payload[formNames.youthId][id],
          [formNames.youthBirthdate]: payload[formNames.youthBirthdate][id],
          [formNames.youthGender]: payload[formNames.youthGender][id],
        },
      };

      const shadowErrors = validateFormData(newData);

      return {
        ...formState,
        data: newData,
        canBeSubmitted: checkFormIsValid(shadowErrors),
      };
    }

    default:
      return {
        ...formInitialState,
      };
  }
};

export const getRadioValue = (val) => {
  if (val === false || val === 'false') return false;
  if (val) return true;
  return null;
};
