import styled from 'styled-components';
import { CLOSE_MODAL_ICON } from 'assets/icons';
import { DEVICES } from '_constants';
import { UiColors } from 'assets/styles/old/global';

export const ModalWrapper = styled.div`
  position: absolute;
  top: 54px;
  right: 5px;
  width: 400px;
  height: auto;
  max-height: calc(100vh - 108px);
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: white;
  overflow: auto;

  @media ${DEVICES.mobileXL} {
    width: 100vw;
  }
`;
export const List = styled.ul``;

export const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  padding: 20px;

  :hover {
    background: #e0e0e0;
    cursor: pointer;
  }
`;

export const ReadCircle = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: ${({ hasReadCircle }) => (hasReadCircle ? UiColors.forestColor : 'transparent')};
  margin-right: 15px;
`;

export const ItemText = styled.span``;

export const CancelIconWrapper = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--dark);
  background: transparent url(${CLOSE_MODAL_ICON}) no-repeat center center;
  padding: 0;
  border: none;
`;

export const ModalTitle = styled.h2`
  padding: 20px 40px;
  font-size: 22px;
  font-weight: 500;
`;
