import { UiColors } from 'assets/styles/old/global';
import styled, { css } from 'styled-components';

export const Message = styled.div`
  font-size: 12px;
  line-height: 12px;
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  width: 100%;
  user-select: none;
`;

export const Error = styled(Message)`
  color: ${UiColors.orangeColor};
`;

export const Title = styled.label`
  display: inline-block;
  margin: 0 0 5px;
  background: var(--white);
  font-size: 14px;
  line-height: 22px;
  color: var(--dark);
  user-select: none;

  ${({ required, disabled }) =>
    required &&
    css`
      &::after {
        content: '*';
        color: ${UiColors.orangeColor};
        position: relative;
        right: -5px;
        cursor: ${disabled ? 'not-allowed' : 'default'};
      }
    `}

  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'default')};
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  color: var(--dark);
  padding: 0 10px;
  border: 1px solid var(--grey);
  background-color: var(--white);
  min-height: 95px;
  font-size: 14px;

  ${({ disabled }) =>
    disabled &&
    css`
      color: var(--light-blue-grey);
      border-color: unset;
      background: var(--pale-grey);
      cursor: not-allowed;
    `}

  ${({ hasError }) =>
    hasError &&
    css`
      border-color: ${UiColors.orangeColor};
    `}
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--white);

  ${({ disabled }) =>
    disabled &&
    css`
      user-select: none;
      background-color: var(--pale-grey);
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    `}
`;

export const MainInputWrapper = styled.div`
  position: relative;
  width: 100%;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      user-select: none;

      ${Title} {
        color: var(--black-two);
      }
    `}
`;

export const RequiredDot = styled.span`
  font-weight: 900;
  color: var(--dark);
`;
