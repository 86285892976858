import { UiColors } from 'assets/styles/old/global';
import styled from 'styled-components';
import { deviceLess } from '_constants/DEVICES';
import Time from './icons/time.svg';

const columnsStyles = {
  submission_column: `
    @media ${deviceLess.laptop} {
    display: flex;

      label {
        display: inline-block;
        word-wrap: break-word;
        min-width: 130px;
      }

      > div {
        width: 100%;
        max-width: 320px !important;
        min-width: 150px;
        padding-left: 10px;
      }
    }
  `,
  '': ``,
};

export const Wrapper = styled.div`
  position: relative;

  ${({ pageColumns }) => pageColumns && columnsStyles[pageColumns]}

  .react-time-picker {
    width: 100%;

    &.error {
      .react-time-picker__wrapper {
        border-color: ${UiColors.orangeColor};
      }
    }

    .react-time-picker__clear-button {
      display: none;
    }

    .react-time-picker__wrapper {
      height: 38px;
      border-color: var(--grey);
    }

    .react-time-picker__inputGroup {
      padding-left: 10px;
      box-sizing: border-box;
      background-image: url(${Time});
      background-repeat: no-repeat;
      background-size: 15px;
      background-position: right 10px center;
    }

    .react-time-picker__inputGroup__input {
      text-align: center;

      &.react-time-picker__inputGroup__hour {
        width: ${({ hours }) => ([10, 11, 12, 22, 23, 0].includes(hours) ? '22px' : '9px')} !important;
      }
      &.react-time-picker__inputGroup__minute {
        width: ${({ minutes }) => (minutes < 10 ? '9px' : '22px')} !important;
      }
    }
  }
`;

export const Label = styled.label`
  margin-bottom: 5px;
  display: block;
  font-size: 14px;
`;

export const Message = styled.div`
  font-size: 12px;
  line-height: 12px;
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  width: 100%;
  user-select: none;
`;

export const Error = styled(Message)`
  color: ${UiColors.orangeColor};
`;
