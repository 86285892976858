import { UiColors } from 'assets/styles/old/global';
import styled from 'styled-components';
import { Image } from 'ui-kit/Img/styles';

export const Wrapper = styled.div`
  width: auto;
`;

export const Item = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

export const Icon = styled(Image)`
  margin-right: 8px;
  width: 12px;
`;

export const Text = styled.p`
  width: auto;
  font-size: 14px;
  font-weight: 500;
  color: ${({ isOnline }) => (isOnline ? 'var(--online)' : UiColors.orangeColor)};
`;
