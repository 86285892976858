/* eslint-disable react/prop-types */
import React from 'react';
import { Img } from 'ui-kit';
import { REMOVE_ICON } from 'assets/icons';
import { components } from 'react-select';

const CustomClearIndicator = ({ props, handleClear }) => {
  return (
    <components.ClearIndicator {...props}>
      <div onClick={handleClear}>
        <Img src={REMOVE_ICON} size={[12, 12]} />
      </div>
    </components.ClearIndicator>
  );
};

export default CustomClearIndicator;
