import styled, { css } from 'styled-components';
import { DEVICES } from '_constants';

export const TableList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
`;

const CellWrapper = styled.button`
  border: none;
  display: flex;
  align-items: center;
  padding: 7px 17px;
  cursor: pointer;
  height: 100%;
  width: 100%;
  background: transparent;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      cursor: default;
      color: var(--dark);
    `}
`;

export const DropdownsBlock = styled.div`
  display: flex;
  align-items: center;

  > div {
    margin: 0 0 0 24px;
  }
`;

export const PaginationBlock = styled.div`
  display: flex;
  align-items: center;

  > div {
    margin: 0 0 0 24px;
  }
`;

const WrapperDropdown = styled.div.attrs(() => ({
  ariaRequired: true,
}))`
  margin-bottom: 27px;
  ${({ isRow }) =>
    isRow &&
    css`
      display: flex;
      align-items: center;
    `};

  @media ${DEVICES.tablet} {
    width: 100%;
    margin-bottom: 15px;
    ${({ isMerger }) =>
      isMerger &&
      css`
        margin-bottom: 10px;
      `}
  }

  @media ${DEVICES.mobileL} {
    width: 100%;
    margin-bottom: 5px;
  }
`;

export const Wrapper = styled.div`
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-right: 1px solid var(--rgb-grey);
  border-bottom: 1px solid var(--rgb-grey);
  border-left: 1px solid var(--rgb-grey);
  box-sizing: border-box;
  padding: 10px 15px;

  ${({ isStoreMerger }) =>
    isStoreMerger &&
    css`
      padding: 0;
      border: none;
    `}

  ${WrapperDropdown} {
    margin-bottom: 0;
  }

  @media ${DEVICES.laptop} {
    flex-direction: column;
    height: auto;

    ${PaginationBlock} {
      order: -1;
    }

    ${DropdownsBlock} {
      margin: 10px 0 0;
    }

    ${PaginationBlock},
    ${DropdownsBlock} {
      justify-content: space-between;
      width: 100%;

      > div {
        margin: 0;
      }
    }
  }

  @media ${DEVICES.tablet} {
    padding: 10px 5px;
  }
`;

export const TableItem = styled.li`
  display: flex;
  flex-direction: column;
  background: ${({ revisitNeeded }) => (revisitNeeded ? '#ffc5c5' : 'var(--white)')};

  &:not(:first-child) {
    margin: 15px 0 0;
  }
`;

export const TableRow = styled.div`
  display: flex;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid var(--rgb-grey);

  &:not(:first-of-type) {
    border-top: none;
  }
`;

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  background: var(--rgb-soft-grey);
  padding: 20px 10px;
  max-width: 200px;
  min-height: 100%;
  flex: 0 0 200px;
  box-sizing: border-box;

  ${CellWrapper} {
    max-width: 100%;
    padding: 0;
    width: auto;
  }

  @media ${DEVICES.mobileL} {
    max-width: 110px;
  }
`;

export const TableBody = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 10px;
  flex: 1 0 100px;
`;

export const TableEditCell = styled.div`
  border-top: 1px solid var(--rgb-grey);
  border-left: 1px solid var(--rgb-grey);
  border-right: 1px solid var(--rgb-grey);
  height: 42px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const TableEditButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  box-sizing: border-box;
`;

export const TableEditText = styled.div`
  margin: 0 0 0 8px;
`;

export const TableColumnControl = styled.div`
  position: relative;
  height: 100%;
`;

export const TableWrapper = styled.div`
  ${TableColumnControl} {
    margin: 0 0 18px;
  }
`;
