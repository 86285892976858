import { UiColors } from 'assets/styles/old/global';
import styled from 'styled-components';
import { DEVICES } from '_constants';

export const Title = styled.div`
  color: rgb(13, 13, 13);
  font-weight: 600;
  max-width: 400px;
  font-size: 26px;
  margin: 0 0 10px;
`;

export const Description = styled.div`
  margin: 0 0 10px;
`;

export const DropWrapper = styled.div`
  padding: 10px 56px;
  min-width: 320px;
  border-bottom: 1px solid var(--soft-grey);
  span {
    color: ${UiColors.orangeColor};
    overflow-wrap: anywhere;
  }
`;

export const Footer = styled.div`
  display: flex;
  padding: 0 56px 16px;
  button {
    margin-top: 25px;
    min-width: 120px;
    &:last-child {
      margin-left: 10px;
    }
  }
`;

export const Wrapper = styled.div`
  box-sizing: border-box;

  @media ${DEVICES.tablet} {
    padding: 10px 15px;
  }
`;

export const RetailerDataContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RetailerText = styled.div`
  span {
    font-weight: bold;
  }
`;

export const ImgContainer = styled.div`
  width: 16px;
  height: 16px;
  background-color: var(--soft-grey);
  border-radius: 10px;
  img {
    margin: 4px 0 0 3px;
  }
`;

export const TextContainer = styled.div`
  width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const CollectorsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 250px;
  ${ImgContainer} {
    cursor: pointer;
  }
`;

export const HeaderContainer = styled.div`
  border-bottom: 1px solid var(--soft-grey);
  padding: 35px 56px 20px;
`;
