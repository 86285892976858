import { UiColors } from 'assets/styles/old/global';
import styled, { css } from 'styled-components';

export const Label = styled.label`
  font-size: 14px;
`;

export const FieldContainer = styled.div`
  position: relative;
  margin-bottom: 10px;
  input {
    width: 100%;
    height: 34px;
    border: 1px solid var(--grey-border);
    max-width: 320px;
    ${({ error }) =>
      error &&
      css`
        border-color: ${UiColors.orangeColor};
      `}
  }
  .special-label {
    display: none;
  }
`;

export const Message = styled.div`
  font-size: 12px;
  line-height: 12px;
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  width: 100%;
  user-select: none;
`;

export const Error = styled(Message)`
  color: ${UiColors.orangeColor};
`;
