/* Libs */
import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import Transition from 'react-transition-group/Transition';
import FocusTrap from 'focus-trap-react';
import ReactDOM from 'react-dom';

/* Styles */
import * as Styled from './styles';

// TODO: Fix Warning: findDOMNode is deprecated in StrictMode
export default class Modal extends PureComponent {
  element = null;

  modalEL = createRef();

  // Bind methods
  onEscape = this.onEscape.bind(this);

  // Life cycle
  componentDidMount() {
    document.addEventListener('keydown', this.onEscape);
    this.element = document.querySelector('body');
    this.forceUpdate();
  }

  componentDidUpdate() {
    const { open } = this.props;

    if (open) {
      this.element.classList.add('modal-open');
    } else {
      this.element.classList.remove('modal-open');
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onEscape);
    this.element.classList.remove('modal-open');
  }

  onEscape({ keyCode }) {
    const { preventEscape, onClose, open } = this.props;
    if (preventEscape) return;
    if (onClose && open && keyCode === 27) {
      onClose();
    }
  }

  render() {
    const {
      children,
      onClose,
      title,
      open,
      withoutPadding,
      isHideElements,
      isFullScreen,
      preventDesktopOverflow,
      autoHeight,
      hideOverflow,
    } = this.props;

    if (!this.element) return null;

    return ReactDOM.createPortal(
      <Transition in={open} timeout={300}>
        {(state) =>
          state !== 'exited' && (
            <Styled.OverallModalWrapper state={state}>
              <FocusTrap>
                <Styled.Modal
                  preventDesktopOverflow={preventDesktopOverflow}
                  isFullScreen={isFullScreen}
                  withoutPadding={withoutPadding}
                  ref={this.modalEL}
                  autoWidth
                  autoHeight={autoHeight}
                  hideOverflow={hideOverflow}
                >
                  {title && !isHideElements && (
                    <Styled.ModalHeader>
                      <Styled.Title isCenterTitle>{title}</Styled.Title>
                    </Styled.ModalHeader>
                  )}
                  {!isHideElements && <Styled.CancelIconWrapper onClick={onClose} aria-hidden="true" />}
                  <Styled.Content withoutPadding={withoutPadding}>{children}</Styled.Content>
                </Styled.Modal>
              </FocusTrap>
            </Styled.OverallModalWrapper>
          )
        }
      </Transition>,
      this.element,
    );
  }
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.string,
  open: PropTypes.bool.isRequired,
  withoutPadding: PropTypes.bool,
  isHideElements: PropTypes.bool,
  preventEscape: PropTypes.bool,
  isFullScreen: PropTypes.bool,
  preventDesktopOverflow: PropTypes.bool,
  autoHeight: PropTypes.bool,
  hideOverflow: PropTypes.bool,
};

Modal.defaultProps = {
  title: null,
  onClose: () => {},
  withoutPadding: false,
  isHideElements: false,
  preventEscape: false,
  isFullScreen: false,
  preventDesktopOverflow: false,
  autoHeight: false,
  hideOverflow: false,
};
