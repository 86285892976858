import styled, { css } from 'styled-components';
import { BUTTON_TYPES } from '_constants';
import { UiColors } from 'assets/styles/old/global';
import { Image } from '../Img/styles';
// import { Wrapper as LoaderWrapper, Loader as LoaderImg } from '../Loader/styles';

export const ButtonTitle = styled.span`
  display: block;
  flex-shrink: 1;
  width: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

/* eslint-disable */
export const ButtonWrapper = styled.button`
  height: 40px;
  padding: 0 5px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  position: relative;
  transition: color 0.2s;
  overflow: hidden;

  ${({ variant }) => {
    switch (variant) {
      case BUTTON_TYPES.DARK:
        return css`
          background-color: ${UiColors.forestColor};
          color: ${UiColors.peachColor};

          :hover {
            filter: saturate(3);
          }
          &:focus {
            border: 3px solid var(--dodger-blue);
            outline-color: var(--dodger-blue);
          }
        `;
      case BUTTON_TYPES.DANGER:
        return css`
          background-color: ${UiColors.mangoColor};
          color: ${UiColors.forestColor};

          :hover {
            filter: saturate(2);
          }
        `;

      case BUTTON_TYPES.GREY:
        return css`
          background-color: var(--white-smoke);
          color: ${UiColors.forestColor};

          :hover {
            background-color: var(--dark);
            color: var(--white);
          }
        `;

      default:
        return css`
          background: transparent;
          color: ${UiColors.forestColor};

          :hover {
            background-color: var(--cod-grey);
          }
        `;
    }
  }}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}

  ${({ isLoading }) =>
    isLoading &&
    css`
      pointer-events: none;
      color: transparent;

      > img {
        opacity: 0;
      }
    `}

  ${Image} {
    margin-right: 5px;
  }
`;
/* eslint-enable */
