import styled from 'styled-components';
import { Button, Input } from 'ui-kit';
import { DEVICES } from '_constants';

export const Wrapper = styled.div`
  height: calc(100% - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 35px 40px;

  @media ${DEVICES.tablet} {
    border: none;
    padding: 25px 16px;
  }
`;

export const FormWrapper = styled.form`
  background: var(--white);
  max-width: 420px;
  width: 100%;
  padding: 35px 35px 25px;
  border: 1px solid var(--grey);
  border-radius: 12px;

  @media ${DEVICES.tablet} {
    height: auto;
    border: none;
    padding: 20px;
  }
`;

export const Title = styled.h1`
  margin-bottom: 20px;
  font-size: 26px;
  font-weight: 500;
  line-height: 1.1;
  text-align: center;
  color: var(--dark);
`;

export const Description = styled.p`
  text-align: center;
  color: var(--dim-grey);
  font-size: 14px;
  line-height: 1.1;
  margin-bottom: 25px;
`;

export const ForgotInput = styled(Input)`
  margin: 0 0 57px;

  @media ${DEVICES.tablet} {
    margin: 0 0 50px;
  }
`;

export const SubmitButton = styled(Button).attrs(() => ({
  type: 'submit',
}))`
  min-width: 147px;
  width: auto;
  max-width: 100%;
  margin: 0 auto;
  border-radius: 3px;
  font-weight: 500;
`;

export const BackButtonWrapper = styled.div`
  padding: 40px 0 0 40px;
`;
