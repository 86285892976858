export const getAuthenticatedUser = (state) => state.auth.isAuthenticated;
export const getIsValidLink = (state) => state.auth.isValidLinkMessage;
export const getUserEmail = (state) => state.auth.email;
export const getUserState = (state) => state.auth.stateAddress;
export const getAppCanStart = (state) => state.auth.appCanStart;
export const getProfilePermissions = (state) => state.auth.permissions;
export const getAgencies = (state) => state.auth.agencies;
export const getUserId = (state) => state.auth.userId;
export const getAllUserInfo = (state) => state.auth;
export const getUserGuideLink = (state) => state.auth.userGuideLink;
export const getAgenciesList = (state) => state.auth.agenciesList;
export const getSecGeogList = (state) => state.auth.secGeogList;
export const getRoleName = (state) => state.auth.role.attributes?.name;
export const getCreatedCampaignByMe = (state) => state.auth.campaignIds;
export const getUserRoleType = (state) => state.auth?.role?.attributes?.role_type;
export const getUserSubdomain = (state) => state.auth.subdomain;
export const getPermissionStatus = (state) => state.auth.permissionStatus;
export const getModularityPermissions = (state) => state.auth.modularityPermissions;
export const getDashboardLinks = (state) => state.auth.dashboardLinks;
export const getDashboardLinksAccount = (state) => state.auth.dashboardLinksAccount;
export const getTimeZone = (state) => state.auth.timeZone;
export const getRetailersImportInfo = (state) => state.auth.retailersImport;
export const getUsersImportInfo = (state) => state.auth.usersImport;
export const getAccountProductInfo = (state) => state.auth.accountProduct;
