import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import Filter from 'ui-kit/old/filter';
import { isValidDate } from 'utils/old/checkSearchDate';
import ManageAssignments from 'pages/ManageAssignments';
import { PageContainer, AccessControl, HasAccess, IsIncludes } from 'modules';
import BackPageButton from 'modules/BackPageButton';
import { AuthEntity, CampaignEntity } from '_entities';
import { useDispatch, useSelector } from 'react-redux';
import { BUTTON_TYPES, URLS, DEFAULT_SORT, PERMISSIONS, MESSAGES, STATUS_CODES, USER_ROLE_TYPES } from '_constants';
import { TableSizeControls, TableTabsWrapper, Button, TabOrganizer, Loader } from 'ui-kit';
import { SetQueryParams } from 'utils/old/setQueryParams';
import processQSParams from 'utils/old/processQSParams';
import AssignmentsTable from 'pages/Campaign/View/components/AssignmentsTable';
import ITEMS_PER_PAGE_OPTIONS from '_constants/old/_constants/ITEMS_PER_PAGE_OPTIONS';
import moment from 'moment';
import { notification } from 'utils/services';
import { getDataFromInclude } from 'utils/custom';
import sortParam from 'utils/old/sortParam';
import CampaignDetails from './components/CampaignDetails';
import * as Styled from './styles';

const { CAMPAIGN_MANAGER, AGENCY_MANAGER, ACCOUNT_MANAGER, MULTI_ACCOUNT_MANAGER } = USER_ROLE_TYPES;

const roleRuleSet = {
  creator: [CAMPAIGN_MANAGER],
  agency: [AGENCY_MANAGER],
  all: [ACCOUNT_MANAGER, MULTI_ACCOUNT_MANAGER],
};

const { getCampaignData, getAssignmentsList, archiveCampaign } = CampaignEntity.actions;
const { getViewCampaignData, getAssignmentsData } = CampaignEntity.selectors;
const { getTimeZone, getUserId, getProfilePermissions } = AuthEntity.selectors;

const pageTitle = 'View campaign';
const assignmentsTitle = 'Assignment status';

const editButtonTitle = 'Edit';
const archiveButtonTitle = { archive: 'Archive', unarchive: 'Unarchive' };
const manageCampaignTitle = 'Manage assignments';

const ViewCampaign = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const userId = useSelector(getUserId);
  const userPermissions = useSelector(getProfilePermissions);
  const [searchValue, setSearchValue] = useState('');
  const [activeTab, useActiveTab] = useState('Campaign info');
  const { data: tableData, pagination } = useSelector(getAssignmentsData);

  const campaignViewData = useSelector(getViewCampaignData);
  const timeZone = useSelector(getTimeZone);

  const [currentPerPage, setCurrentPerPage] = useState(ITEMS_PER_PAGE_OPTIONS[1].value);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOption, setSortOption] = useState({
    sortBy: DEFAULT_SORT.retailerVisits.prop,
    sortOrder: DEFAULT_SORT.retailerVisits.order,
  });
  const [isLoading, setIsLoading] = useState(true);

  const tabsTitle = useMemo(() => {
    const baseTabTitle = ['Campaign info', assignmentsTitle];
    if (IsIncludes(userPermissions, PERMISSIONS.CHANGE_CAMPAIGN)) {
      baseTabTitle.splice(1, 0, manageCampaignTitle);
    }
    return baseTabTitle;
  }, [userPermissions]);

  const getAssignments = async ({ page = 1, perPage, sortBy, sortOrder, ...qsParams }) => {
    const campaign = id;
    const params = processQSParams({ page, perPage, sortOrder, campaign, sortBy, ...qsParams });
    try {
      await dispatch(getAssignmentsList(params));
    } catch (e) {
      console.log(e);
    }
  };

  const { data, included } = campaignViewData || {};

  const { campaign_name: campaignName, archived, sec_geog_name: secGeogName } = data?.attributes || {};

  const agencyId = data?.relationships?.agency.data.id;
  const formId = data?.relationships?.form.data.id;

  const agency = included && getDataFromInclude(included, 'Agency', agencyId)?.attributes?.name;
  const form = included && getDataFromInclude(included, 'Form', formId)?.attributes?.name;

  const handleEdit = () => {
    history.push(`${URLS.campaignPages.edit}/${id}`);
  };

  const handleArchive = useCallback(async () => {
    const archiveData = {
      data: {
        type: 'Campaign',
        id,
        attributes: {
          archived: archived ? null : moment().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        },
      },
    };

    try {
      await dispatch(archiveCampaign(id, archiveData));
      notification.success(archived ? MESSAGES.CAMPAIGN.UNARCHIVE.SUCCESS : MESSAGES.CAMPAIGN.ARCHIVE.SUCCESS);
      history.push(`${URLS.campaign_list}`);
    } catch (err) {
      console.log(err);
    }
  }, [archived]);

  const loadAssignments = useCallback(
    async ({
      page = currentPage,
      perPage = currentPerPage,
      sortBy = sortOption.sortBy,
      sortOrder = sortOption.sortOrder,
      search = searchValue,
    } = {}) => {
      try {
        setIsLoading(true);
        await getAssignments({ page, perPage, sortBy, sortOrder, search });
        SetQueryParams({
          queryString: {
            page,
            page_size: perPage,
            ordering: sortParam({ sortBy, sortOrder }),
            search,
          },
          history,
        });
      } catch (e) {
        console.log('e', e);
      }
    },
    [currentPage, dispatch, currentPerPage, SetQueryParams, {}, sortOption],
  );

  useEffect(() => {
    if (activeTab === assignmentsTitle) {
      loadAssignments();
    }
  }, [activeTab]);

  const sortHandler = useCallback(
    (sortBy, sortOrder) => {
      setSortOption({ sortBy, sortOrder });
      loadAssignments({ sortBy, sortOrder });
    },
    [loadAssignments, setSortOption],
  );

  const handlePerPageChange = useCallback(
    ({ value }) => {
      if (currentPerPage === value) return;
      setCurrentPerPage(value);
      setCurrentPage(1);
      loadAssignments({ perPage: value, page: 1 });
    },
    [setCurrentPerPage, setCurrentPage, loadAssignments],
  );

  const handlePageChange = useCallback(
    ({ value }) => {
      if (currentPage === value) return;
      setCurrentPage(value);
      loadAssignments({ page: value });
    },
    [loadAssignments, setCurrentPage],
  );

  useEffect(() => {
    (async () => {
      try {
        await loadAssignments({ page: currentPage, perPage: currentPerPage });
        await dispatch(getCampaignData(id));
      } catch (err) {
        const statusCode = err.response?.status;
        if (statusCode === STATUS_CODES.NOT_FOUND) {
          history.push(URLS.notFound);
        } else {
          console.log('err: ', err);
        }
      }
    })();
  }, []);

  const canSeePrivateStatus = HasAccess(
    [PERMISSIONS.CHANGE_PRIVATE_CAMPAIGN_FLAG, PERMISSIONS.ADD_PRIVATE_CAMPAIGN_FLAG],
    data?.relationships,
    roleRuleSet,
  );

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, [data, pagination]);

  const handleOnChangeInputSearch = (searchVal) => {
    if (isEmpty(searchVal)) {
      setSearchValue('');
    }
    setSearchValue(searchVal);
  };

  const handleClickInput = () => {
    if (isEmpty(searchValue)) return;
    const searchString = isValidDate(searchValue);
    loadAssignments({ search: searchString, startPage: 1 });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      const searchString = isValidDate(searchValue);
      loadAssignments({ search: searchString, startPage: 1 });
    }
  };

  const handleRemoveSearch = () => {
    if (isEmpty(searchValue)) return;
    loadAssignments({ search: '', startPage: 1 });
    setSearchValue('');
  };

  const returnTabView = useCallback(
    (param) => {
      switch (param) {
        case 'Campain Details':
          return (
            <CampaignDetails
              included={included}
              data={data}
              timeZone={timeZone}
              canSeePrivateStatus={canSeePrivateStatus}
            />
          );
        case assignmentsTitle:
          return (
            <Styled.PageWrapper>
              <Styled.FilterWrapper className="table-filters">
                <Filter
                  type="null"
                  placeholder="Search"
                  onChangeInput={handleOnChangeInputSearch}
                  handleClickInput={handleClickInput}
                  onKeyDown={handleKeyDown}
                  onRemoveSearch={handleRemoveSearch}
                  searchValue={searchValue}
                  isWidth
                />
              </Styled.FilterWrapper>
              <TableTabsWrapper
                hasTabs={false}
                amount={pagination?.count || 0}
                hasBorders
                tableComponent={
                  <Styled.TableContainer>
                    <AssignmentsTable
                      data={tableData}
                      sortOption={sortOption}
                      sortHandler={sortHandler}
                      userId={userId}
                    />
                    <TableSizeControls
                      itemsPerPageProps={{
                        handlePerPageChange,
                        value: currentPerPage,
                      }}
                      pageCountProps={{
                        value: currentPage,
                        pages: pagination?.pages || 1,
                        handlePageChange,
                      }}
                      paginationInfoProps={{
                        total: pagination?.count || 0,
                        currentPage,
                        perPage: currentPerPage,
                      }}
                      paginationNavProps={{
                        currentPage,
                        handlePageChange,
                        pages: pagination?.pages || 1,
                        titleTable: 'Youth',
                      }}
                    />
                  </Styled.TableContainer>
                }
              />
            </Styled.PageWrapper>
          );
        case manageCampaignTitle:
          return <ManageAssignments />;
        default:
          return (
            <CampaignDetails
              included={included}
              data={data}
              timeZone={timeZone}
              canSeePrivateStatus={canSeePrivateStatus}
            />
          );
      }
    },
    [data, activeTab, tableData, searchValue],
  );

  return (
    <PageContainer>
      <Styled.MainContainer>
        {isLoading && <Loader />}
        <Styled.TopLine>
          <Styled.TitleWrapper>
            <BackPageButton />
            <Styled.Title>{pageTitle}</Styled.Title>
            <Styled.OddInfoWrapper>
              <Styled.HGroup>
                <Styled.H3title>
                  Campaign Name: <span>{campaignName}</span>
                </Styled.H3title>
                <Styled.H3title>
                  Secondary Geography: <span>{secGeogName}</span>
                </Styled.H3title>
              </Styled.HGroup>
              <Styled.HGroup>
                <Styled.H3title>
                  Agency: <span>{agency}</span>
                </Styled.H3title>
                <Styled.H3title>
                  Form: <span>{form}</span>
                </Styled.H3title>
              </Styled.HGroup>
            </Styled.OddInfoWrapper>
          </Styled.TitleWrapper>
          <AccessControl permission={PERMISSIONS.CHANGE_CAMPAIGN} data={data?.relationships} roleRuleSet={roleRuleSet}>
            <Styled.ButtonWrapper>
              <Button
                onClick={handleArchive}
                variant={BUTTON_TYPES.DANGER}
                text={archived ? archiveButtonTitle.unarchive : archiveButtonTitle.archive}
              />
              <Button onClick={handleEdit} variant={BUTTON_TYPES.DANGER} text={editButtonTitle} />
            </Styled.ButtonWrapper>
          </AccessControl>
        </Styled.TopLine>
        <TabOrganizer
          tabsTitle={tabsTitle}
          useActiveTab={useActiveTab}
          selectedValue={activeTab}
          minWidth="100%"
          isView
          isBorder
          minHeight="42px"
          isMargin="0 0 8px 0"
          inputId="CampaignsView"
        />
        {returnTabView(activeTab)}
      </Styled.MainContainer>
    </PageContainer>
  );
};

export default ViewCampaign;
