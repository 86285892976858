import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  label {
    padding: 0;
  }
`;

export const Title = styled.label`
  font-size: 14px;
  margin: 0 5px 0 0;
`;
/* eslint-enable */
