import styled from 'styled-components';
import { DEVICES } from '_constants';
import { deviceLess } from '_constants/DEVICES';
import { Section, Content } from 'ui-kit/Section/styles';

export const MainContainer = styled.div`
  padding: 30px 25px 40px;
  @media ${deviceLess.desktopHd} {
    display: flex;
    flex-direction: column;
    max-width: 1920px;
    margin: auto;
  }
`;

export const TopLine = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const TitleWrapper = styled.div`
  > a {
    margin-bottom: 15px;
  }
`;

export const Title = styled.h1`
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 22px;
`;

export const Info = styled.p`
  font-size: 14px;
  color: var(--dim-grey);
`;

export const ButtonWrapper = styled.div`
  display: flex;

  > button {
    margin-left: 25px;
    min-width: 135px;
  }

  @media ${DEVICES.tablet} {
    flex-direction: column;

    > button {
      margin-bottom: 10px;
    }
  }
`;

export const Inner = styled.div`
  display: flex;
  flex-flow: column nowrap;
  border: 1px solid var(--grey);
  font-weight: normal;

  @media ${DEVICES.tablet} {
    display: block;
    border: none;
  }

  @media ${DEVICES.laptop} {
    flex-direction: column;
  }

  ${Section} {
    border: none;
    flex: 1 0;
    width: 100%;
    overflow: hidden;

    :first-of-type {
      @media ${DEVICES.tablet} {
        margin-bottom: 25px;
        border: 1px solid var(--grey);
      }
    }

    ${Content} {
      padding: 0;
      padding-left: 20px;

      @media ${deviceLess.laptop} {
        padding: 0;
      }
    }

    :nth-child(2) {
      border-left: 1px solid var(--grey);
      border-right: 1px solid var(--grey);

      @media ${DEVICES.tablet} {
        margin-bottom: 25px;
        border: 1px solid var(--grey);
      }
    }

    :last-of-type {
      @media ${DEVICES.tablet} {
        border: 1px solid var(--grey);
      }
    }
  }
`;

export const WrapperSelect = styled.div`
  margin-bottom: 20px;
`;

export const InputWrapper = styled.div`
  margin-bottom: 25px;
`;

export const Location = styled.div``;

export const LocationLabel = styled.span`
  display: block;
  font-size: 16px;
  color: var(--dim-grey);
  margin-bottom: 8px;
`;

export const LocationText = styled.p`
  font-size: 18px;
  color: var(--dark);
  min-height: 18px;
`;

export const MapWrapper = styled.div`
  padding: 0;
  margin: 0 0 20px;
  width: 100%;
  min-height: 260px;

  @media ${DEVICES.tablet} {
    height: 210px;
  }
`;

export const GisInfoWrapper = styled.div`
  padding: 0 15px;
`;

export const GisInfo = styled.p`
  padding: 0;
  margin: 0 0 10px;
  color: var(--dim-grey);
`;

export const EditWrapper = styled.div`
  padding: 15px;

  > button {
    min-width: 135px;
  }
`;

export const EditDescription = styled.p`
  margin: 0 0 20px;
  color: var(--dark);
`;

export const UpdateLocationButtonWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;

  > button {
    min-width: 135px;

    :not(:last-of-type) {
      margin-right: 15px;
    }
  }
`;

export const Counter = styled.span`
  font-weight: bold;
  font-size: 16px;
`;

export const RetailerCountContainer = styled.div`
  width: calc(100% + 40px);
  border-bottom: ${({ isBorder }) => isBorder && `1px solid var(--grey)`};
  padding-left: 20px;
  margin: 0 0 20px -20px;
`;

export const RetailerAdditionalInfo = styled.div`
  display: ${({ isShow }) => (isShow ? `none` : `block`)};
  width: 100%;
`;

export const InputSubText = styled.div`
  margin-top: ${({ isError }) => (isError ? `20px` : `0`)};
`;

export const TableContainer = styled.div``;

export const Dl = styled.dl`
  margin: 0;
  padding: 0;
  position: relative;
`;

export const Dt = styled.dt`
  margin: 0 0 10px;
  padding: 0;
  font-size: 16px;
  line-height: 1.17;
  color: var(--dim-grey);
`;

export const Dd = styled.dd`
  font-size: 18px;
  line-height: 1.33;
  color: var(--dark);

  :not(:last-of-type) {
    margin-bottom: 25px;
  }

  a {
    color: var(--curious-blue);
  }

  img {
    display: block;
    width: auto;
    max-height: 206px;
    height: 100%;
    object-fit: contain;
  }
`;

export const ItemWrapper = styled.div`
  position: relative;
  max-height: 100%;
  min-height: 60px;
  padding: 10px 0;

  dd {
       > a {
      width: 100%;
      min-width: 200px;
      margin: auto;
      
      display: inline-block;
      word-wrap: break-word;
    }
  }
  @media ${deviceLess.laptop} {
    display: flex;
    flex-direction: row;
    max-height: 40px;
    min-height: 40px;
    padding: 0 20px;
    margin-bottom: 0;

    dt {
      width: 100%;
      min-width: 200px;
      max-width: 400px;
      display: inline-block;
      word-wrap: break-word;
      margin: auto;
    }
    > dd {
      padding-left: 25px;
      width: 100%;
      min-width: 200px;
      margin: auto;
      
      display: inline-block;
      word-wrap: break-word;
    }
  }
}`;

export const SectionGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`;

export const VerticalBorder = styled.div`
  @media ${deviceLess.tablet} {
    border-right: 1px solid #e0e0e0;
    position: absolute;
    width: ${({ isDetails }) => (isDetails ? '50%' : '45%')};
    min-width: ${({ isDetails }) => (isDetails ? '50%' : '45%')};
    max-width: ${({ isDetails }) => (isDetails ? '50%' : '45%')};
    display: inline-block;
    height: 100%;
    top: 0;
    z-index: 1;
  }
`;

export const VerticalBorderView = styled.div`
  @media ${deviceLess.laptop} {
    border-right: 1px solid #e0e0e0;
    position: absolute;
    height: 100%;
    top: 0;
    width: 100%;
    min-width: 200px;
    max-width: 400px;
    display: inline-block;
    word-wrap: break-word;
    z-index: 1;
  }
`;

export const HorizontalBorder = styled.div`
  border-bottom: 1px solid #e0e0e0;
  position: absolute;
  top: 100%;
  left: -30px;
  width: 200%;
  z-index: 1;
  filter: opacity(0.4);

  @media ${deviceLess.laptop} {
    height: 100%;
    top: 0;
    left: -30px;
    width: 200%;
    z-index: 1;
    filter: opacity(0.4);
  }
`;

export const H3title = styled.h3`
  font-weight: 100;

  span {
    font-weight: 500;
  }
`;

export const OddInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  @media ${DEVICES.tablet} {
    flex-direction: column;
  }
`;

export const HGroup = styled.div`
  padding-right: 50px;
  h3 {
    padding-left: 10px;

    @media ${DEVICES.tablet} {
      padding-bottom: 10px;
    }
  }
`;

export const FilterWrapper = styled.div`
  padding: 20px 20px;
`;

export const PageWrapper = styled.div`
  border: 1px solid var(--grey); ;
`;
