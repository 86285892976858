import moment from 'moment';
import React, { memo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'ui-kit';
import { BUTTON_TYPES, URLS, MESSAGES, PERMISSIONS, USER_ROLE_TYPES } from '_constants';
import BackPageButton from 'modules/BackPageButton';
import { notification } from 'utils/services';
import { OnlineIndicator, AccessControl, IsMassAccount, HasAccess } from 'modules';
import { getDataFromInclude, capitalizeFirstLetter } from 'utils/custom';
import { AttachToCampaignModal, RestoreDeleteModal } from 'modules/Modals';
import { VisitEntity, RetailerEntity, SurveyEntity, CollectEntity } from '_entities';
import { PAGE_TYPE } from '../VisitView/constants';
import * as Styled from './styles';

const { getVisitData, getVisitInfo } = VisitEntity.selectors;
const { sendViolationPDF, bulkDeleteVisits, bulkRestoreVisits } = VisitEntity.actions;
const { getRetailerInfo } = RetailerEntity.selectors;
const { getSingleSurveyForm } = SurveyEntity.selectors;
const { getAssignmnetsAttachedToCampaign } = CollectEntity.actions;

const EntityHeader = ({ pageType, submitFunction, toView, title, showOnlineIndicator, updateVisitInfo, viewUrl }) => {
  const data = {
    [PAGE_TYPE.ADD]: useSelector(getSingleSurveyForm),
    [PAGE_TYPE.EDIT]: useSelector(getVisitData),
    [PAGE_TYPE.VIEW]: useSelector(getVisitData),
  };
  const entityData = data[pageType];
  const visitInfo = useSelector(getVisitInfo);
  const retailerInfo = useSelector(getRetailerInfo);
  const [isAssignmentsModalOpen, setIsAssignmentsModalOpen] = useState(false);
  const [assignmentsAttached, setAssignmentsAttached] = useState({});
  const [modalStates, setModalStates] = useState({ isOpen: false, modalType: '', modalMessage: '', payload: {} });
  const [isRestoreDeleteBtnDisabled, setIsRestoreDeleteBtnDisabled] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const entityAttributes = entityData.data?.attributes;
  const entityIncluded = entityData.included;
  const { headerTitle, editPageUrl } = visitInfo;
  const isMassAccount = IsMassAccount();

  // Campaign is optional array item
  const agency = entityIncluded && getDataFromInclude(entityIncluded, 'Agency');
  const form = entityIncluded && getDataFromInclude(entityIncluded, 'Form');
  const retailer = (entityIncluded && getDataFromInclude(entityIncluded, 'Retailer')) || retailerInfo;
  const campaign = entityIncluded && getDataFromInclude(entityIncluded, 'Campaign');
  const address = retailer?.attributes?.address;
  const address2 = retailer?.attributes?.address2;
  const city = retailer?.attributes?.city;
  const state = retailer?.attributes?.state;
  const zipcode = retailer?.attributes?.zipcode;
  const retailerName = retailer?.attributes && capitalizeFirstLetter(retailer.attributes.name);
  const formName = form?.attributes?.name || entityAttributes?.name;
  const buttonText = pageType === PAGE_TYPE.VIEW ? 'Edit' : 'Save changes';
  const isDeleted = entityAttributes?.deleted;
  const relationships = entityData?.data?.relationships;
  const visitCreatorId = relationships?.user?.data?.id;
  const userCreator = entityIncluded && getDataFromInclude(entityIncluded, 'User', visitCreatorId);
  const dataCollector = visitCreatorId && [visitCreatorId];
  const { MULTI_ACCOUNT_MANAGER, ACCOUNT_MANAGER, AGENCY_MANAGER, CAMPAIGN_MANAGER, DATA_COLLECTOR } = USER_ROLE_TYPES;

  const permissionData = {
    ...relationships,
    secGeog: entityAttributes?.aggregationpolygon_set?.map((polygon) => `${polygon}`),
    data_collector: dataCollector,
  };

  const restoreRuleSet = { all: [ACCOUNT_MANAGER, MULTI_ACCOUNT_MANAGER] };

  const roleRuleSet = {
    assigned: [DATA_COLLECTOR],
    agency: [AGENCY_MANAGER],
    agencySecGeogs: [CAMPAIGN_MANAGER],
    all: [ACCOUNT_MANAGER, MULTI_ACCOUNT_MANAGER],
  };

  const canViewCampaign = HasAccess(PERMISSIONS.VIEW_CAMPAIGN, permissionData, roleRuleSet);

  const getAttachedCampaign = async () => {
    if (pageType !== PAGE_TYPE.VIEW || !canViewCampaign) return;

    try {
      const { id: retailerId } = data[PAGE_TYPE.VIEW].data?.relationships.retailer.data;
      const { id: formId } = data[PAGE_TYPE.VIEW].data?.relationships.form.data;
      const userCreatorEmail = userCreator?.attributes?.email;
      const encodedUserCreatorEmail = encodeURIComponent(userCreatorEmail);

      const queryParams = `form=${formId}&retailer=${retailerId}&email=${encodedUserCreatorEmail}`;

      const res = await dispatch(getAssignmnetsAttachedToCampaign(queryParams));
      setAssignmentsAttached(res);
    } catch (e) {
      console.log('error', e);
    }
  };

  useEffect(() => {
    if (pageType === PAGE_TYPE.VIEW) {
      getAttachedCampaign();
    }
  }, [pageType]);

  const violationSummaryHandler = useCallback(async () => {
    const date = entityData.data.attributes.submitted;
    const violationDate = {
      date: moment(date).format('ll'),
      time: `${moment(date).format('LTS')}`,
    };
    try {
      await dispatch(sendViolationPDF(id, violationDate));
      notification.success(MESSAGES.VISIT.VIOLATION_PDF.SUCCESS);
    } catch (err) {
      console.log(err);
      notification.error(MESSAGES.VISIT.VIOLATION_PDF.ERROR);
    }
  });

  const getCampaign = useCallback(() => {
    if (campaign) {
      return <Link to={`${URLS.campaign}/${campaign.id}`}>{campaign.attributes.campaign_name}</Link>;
    }
    return null;
  });

  const buttonClickHandler = useCallback(() => {
    switch (pageType) {
      case PAGE_TYPE.VIEW:
        history.push(`${editPageUrl}/${entityData.data.id}`);
        break;
      case PAGE_TYPE.EDIT:
        submitFunction();
        break;
      default:
        break;
    }
  });

  const handleOpenAssignmentsModal = () => {
    setIsAssignmentsModalOpen(() => true);
  };

  const handleCloseAssignmentsModal = () => {
    setIsAssignmentsModalOpen(() => false);
  };

  const handleDeleteVisits = async (payload) => {
    try {
      await dispatch(bulkDeleteVisits(payload));
      await updateVisitInfo();
      notification.success('Visits was successfully deleted');
      history.push(viewUrl);
    } catch (err) {
      const errorMessageArray = err?.response?.data?.errors;

      if (Array.isArray(errorMessageArray)) {
        const [errorMessage] = errorMessageArray;
        notification.error(errorMessage.detail);
      } else {
        notification.error('Something went wrong during the request');
      }
    }
  };

  const handleRestoreDeleteButton = async () => {
    const payload = { data: [{ type: 'Visit', id }] };
    setIsRestoreDeleteBtnDisabled(true);

    try {
      if (isDeleted) {
        await dispatch(bulkRestoreVisits(payload));
        await updateVisitInfo();
        notification.success('Visits were successfully restored');
      } else {
        setModalStates(() => ({
          isOpen: true,
          modalType: 'delete',
          modalMessage: 'Are you sure you want to delete this visit?',
          payload,
        }));
      }
    } catch (err) {
      const errorMessageArray = err?.response?.data?.errors;

      if (Array.isArray(errorMessageArray)) {
        const [errorMessage] = errorMessageArray;
        notification.error(errorMessage.detail || errorMessage);
      } else {
        notification.error('Something went wrong during the request');
      }
      setIsRestoreDeleteBtnDisabled(false);
    }
  };

  const closeRestoreDeleteModal = () => {
    setModalStates({ isOpen: false, modalType: '' });
  };

  const address2String = address2 ? `${address2}, ` : '';
  const retailerAddress = `${address}, ${address2String}${city}, ${state}, ${zipcode}`;

  return (
    <Styled.Wrapper>
      <Styled.TopWrapper>
        <Styled.TitleCol>
          <BackPageButton />
          <Styled.Title>{headerTitle || title}</Styled.Title>
          {showOnlineIndicator && (
            <Styled.OnlineWrapper>
              <OnlineIndicator />
            </Styled.OnlineWrapper>
          )}
          {pageType !== PAGE_TYPE.VIEW && <Styled.SubTitle>* - required fields</Styled.SubTitle>}
        </Styled.TitleCol>
        {pageType !== PAGE_TYPE.ADD && (
          <Styled.ButtonWrap>
            {pageType === PAGE_TYPE.VIEW && isDeleted && (
              <AccessControl roleRuleSet={restoreRuleSet}>
                <Button
                  onClick={handleRestoreDeleteButton}
                  disabled={isRestoreDeleteBtnDisabled}
                  text="Restore visit"
                  variant={BUTTON_TYPES.DANGER}
                />
              </AccessControl>
            )}
            {pageType === PAGE_TYPE.VIEW && !isDeleted && (
              <AccessControl permission={PERMISSIONS.DELETE_VISIT} roleRuleSet={roleRuleSet} data={permissionData}>
                <Button onClick={handleRestoreDeleteButton} text="Delete visit" variant={BUTTON_TYPES.DARK} />
              </AccessControl>
            )}
            {pageType === PAGE_TYPE.VIEW && !isDeleted && (
              <AccessControl
                permission={[PERMISSIONS.CHANGE_VISIT, PERMISSIONS.VIEW_CAMPAIGN]}
                roleRuleSet={roleRuleSet}
                data={permissionData}
              >
                <Button onClick={handleOpenAssignmentsModal} text="Attach to campaign" variant={BUTTON_TYPES.DANGER} />
              </AccessControl>
            )}
            {pageType === PAGE_TYPE.VIEW && isMassAccount && form?.attributes?.send_summary_email && !isDeleted && (
              <AccessControl permission={PERMISSIONS.VIEW_OTHER_VIOLATION_INFO} data={relationships}>
                <Button onClick={violationSummaryHandler} text="Email visit summary" variant={BUTTON_TYPES.DARK} />
              </AccessControl>
            )}
            {!isDeleted && pageType === PAGE_TYPE.VIEW && (
              <AccessControl permission={PERMISSIONS.CHANGE_VISIT} roleRuleSet={roleRuleSet} data={permissionData}>
                <Button onClick={buttonClickHandler} text={buttonText} variant={BUTTON_TYPES.DANGER} />
              </AccessControl>
            )}
          </Styled.ButtonWrap>
        )}
      </Styled.TopWrapper>
      <Styled.Info>
        <Styled.P>
          Retailer name: <Link to={`${URLS.retailers}/${retailer.id}`}>{retailerName}</Link>
        </Styled.P>
        <Styled.P>
          Address: <Styled.Details>{retailerAddress}</Styled.Details>
        </Styled.P>
        {formName && (
          <Styled.P>
            Form: <Styled.Details>{formName}</Styled.Details>
          </Styled.P>
        )}
        <Styled.P>
          Agency: <Styled.Details>{agency?.attributes.name}</Styled.Details>
        </Styled.P>
        {toView.campaign && getCampaign() && (
          <AccessControl permission={PERMISSIONS.VIEW_CAMPAIGN} roleRuleSet={roleRuleSet} data={permissionData}>
            <Styled.P>
              Campaign: <Styled.Details>{getCampaign()}</Styled.Details>
            </Styled.P>
          </AccessControl>
        )}
      </Styled.Info>
      <AttachToCampaignModal
        isOpen={isAssignmentsModalOpen}
        onClose={handleCloseAssignmentsModal}
        assignmentsAttached={assignmentsAttached}
        visitId={id}
        updateVisitInfo={updateVisitInfo}
        currentCampaignId={campaign?.id}
      />
      {modalStates.isOpen && (
        <RestoreDeleteModal
          type={modalStates.modalType}
          isOpen={modalStates.isOpen}
          payload={modalStates.payload}
          onClose={closeRestoreDeleteModal}
          postRestoreOrDelete={handleDeleteVisits}
          modalMessage={modalStates.modalMessage}
          modalType={modalStates.modalType}
          page="Retailers"
          dispatch={dispatch}
        />
      )}
    </Styled.Wrapper>
  );
};

EntityHeader.propTypes = {
  pageType: PropTypes.string.isRequired,
  title: PropTypes.string,
  toView: PropTypes.object,
  submitFunction: PropTypes.func,
  showOnlineIndicator: PropTypes.bool,
  updateVisitInfo: PropTypes.func,
  viewUrl: PropTypes.string,
};

EntityHeader.defaultProps = {
  toView: {
    campaign: true,
  },
  submitFunction: () => {},
  title: '',
  showOnlineIndicator: false,
  updateVisitInfo: () => {},
  viewUrl: '',
};

export default memo(EntityHeader);
