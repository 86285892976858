import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { DEVICES } from '_constants';
import { CLOSE_MODAL_ICON } from 'assets/icons';
import { UiColors } from 'assets/styles/old/global';

export const WrapperName = styled(Link)`
  color: var(--curious-blue);
  font-size: 14px;
  text-decoration: underline;
  margin-bottom: 20px;
  display: block;
  cursor: pointer;
  font-weight: 600;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  display: block;
  width: 15px;
  height: 15px;
  background: url(${CLOSE_MODAL_ICON});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 15px;
  font-size: 0;
  border: none;
`;
export const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 54px;
  z-index: 10;
  overflow: hidden;
  max-width: 474px;
  width: 100%;
  height: calc(100% - 54px);
  padding-top: 50px;
  background-color: var(--white);

  label {
    font-size: 14px;
  }

  ${WrapperName}:first-of-type {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }
`;

export const SecondTable = styled.div`
  box-sizing: border-box;
  padding: 0 20px 20px;

  label {
    font-size: 16px;
  }

  .rt-th {
    button {
      svg {
        display: none;
      }
    }
  }
`;

export const ThirdTable = styled.div`
  margin: 20px 0;
  box-sizing: border-box;
  padding: 0 10px;

  label {
    font-size: 16px;
  }
`;

export const WrapperHeaders = styled.div`
  margin: 0 24px 24px 24px;

  @media ${DEVICES.tablet} {
    margin: 35px 0px 0px 24px;
  }
  @media ${DEVICES.mobileL} {
    margin: 35px 0px 0px 10px;
  }
`;

export const StyleAddress = styled.div`
  color: var(--dark);
  font-size: 14px;
  margin-bottom: 35px;
`;

export const StyleSuspencions = styled.div`
  color: ${UiColors.orangeColor};
  font-size: 14px;

  & + & {
    padding-top: 10px;
  }
`;

export const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;

  > button {
    flex: 1 0;
    padding: 0 10px;

    :not(:last-of-type) {
      margin-right: 10px;
    }
  }
`;

export const Header = styled.div`
  display: none;

  @media ${DEVICES.tablet} {
    display: block;
    background: var(--white-smoke);
    height: 56px;
    padding: 16px;
    box-sizing: border-box;
    top: 1px;
    position: absolute;
    z-index: 1;
    width: 100vw;
    left: 0;
  }

  @media ${DEVICES.mobileL} {
    width: 100%;
    height: auto;

    box-sizing: border-box;
    padding: 10px;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-left: 7px;

  @media ${DEVICES.mobileL} {
    margin-left: 0px;
  }
`;

export const TableTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 10px;
`;
