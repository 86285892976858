import styled, { css } from 'styled-components';
import { DEVICES } from '_constants';
import { UiColors } from 'assets/styles/old/global';

const POPUP_POSITIONS = {
  0: '25%',
  1: '45%',
  2: '70%',
};

export const ButtonSideBar = styled.button.attrs(() => ({
  ariaRequired: true,
  role: 'button',
}))`
  font-size: 14px;
  text-align: center;
  color: ${UiColors.peachColor};
  border: none;
  padding: 10px;
  border-radius: 2px;
  width: 150px;
  background-color: ${UiColors.forestColor};
  cursor: pointer;
  &:focus {
    border: 3px solid ${UiColors.focusColor};
    outline-color: ${UiColors.focusColor};
  }
`;

export const ButtonCell = styled.div`
  display: flex;
`;

export const Title = styled.div`
  font-size: 14px;
  line-height: 16px;
  margin: 0 0 12px;
`;

export const SubTitle = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: #737373;
  margin: 0 0 15px;
`;

export const ListItem = styled.li`
  &:not(:first-child) {
    margin: 30px 0 0;
  }
`;

export const List = styled.ul`
  background: var(--white);
  flex-basis: 355px;
  max-width: 30%;
  border-top: 1px solid ${UiColors.strokeColor};
  border-right: 1px solid ${UiColors.strokeColor};
  border-bottom: 1px solid ${UiColors.strokeColor};
  padding: 18px;
  margin: 0;
  list-style: none;

  @media ${DEVICES.laptop} {
    max-width: 100%;
    border: 1px solid ${UiColors.strokeColor};
    margin: 15px 0 0;
    min-width: 100px;
    flex-basis: auto;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  :hover {
    cursor: pointer;
  }
`;

export const MiniButtonSideBar = styled.button.attrs(() => ({
  ariaRequired: true,
  role: 'button',
}))`
  border: none;
`;

export const TooltipContainer = styled.div`
  display: none;
  position: absolute;
  z-index: 10000;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  font-size: 14px;
  min-width: 600px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  top: 75%;
  left: ${({ buttonIndex }) => POPUP_POSITIONS[buttonIndex]}};
  transform: translateX(-100%);
`;

export const ToolTipSection = styled.span`
  display: inline-block;

  ${({ isSubDesc }) =>
    isSubDesc &&
    css`
      margin-top: 10px;
    `}
`;

export const TooltipContent = styled.div`
  white-space: normal;
`;

export const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover ${TooltipContainer} {
    display: flex;
    opacity: 1;
  }
`;
