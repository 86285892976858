import { UiColors } from 'assets/styles/old/global';
import styled, { css } from 'styled-components';

export const Tab = styled.button`
  text-decoration: none;
  text-transform: capitalize;
  color: ${UiColors.forestColor};
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  border: none;
  background: transparent;
  width: 100%;
  text-transform: uppercase;

  &:focus {
    box-shadow: 0 0 0 2px var(--focus);
  }

  > img {
    margin: 0 0 0 6px;
    transition: transform 0.3s;
    transform: rotate(${({ isActive }) => (isActive ? '-90deg' : '90deg')});
  }
`;

export const List = styled.ul`
  margin: 0;
`;

export const ListItem = styled.li`
  padding: 0;
  margin: 0;
  list-style: none;
  a {
    text-transform: capitalize;
    margin-left: 45px;
  }
  min-height: 48px;
  display: flex;
  ${({ border }) =>
    border &&
    css`
      border-bottom: 1px solid var(--grey);
    `};
  ${({ borderAll }) =>
    borderAll &&
    css`
      border-bottom: 1px solid var(--grey);
      border-top: 1px solid var(--grey);
    `};
`;

export const MenuWrapper = styled.div``;

export const RoutesListItem = styled.li`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  min-height: 48px;
  justify-content: center;

  button {
    margin-left: 25px;
    height: 40px;
    max-width: max-content;
  }
`;

export const LogOut = styled.button`
  text-decoration: none;
  border: none;
  background: transparent;
  width: 100%;
  text-align: left;
  text-transform: capitalize;
`;

export const DeviderText = styled.div`
  display: none;
`;
