import { INPUT_TYPES } from 'modules/VisitEdit/constants';
import { addTimezoneToDate } from './addTimezoneToDate';
import { getDataFromInclude } from './getDataFromInclude';
import { getFileFormat } from './getFileFormat';

function getCurrentValue(data, entityName) {
  const name = entityName?.toLowerCase();
  if (data[name] === undefined) {
    return data.metadata[name.toLowerCase()];
  }
  return data[name];
}

/**
 * Convert JSON from backend
 *
 * @example convertVisitJSON({ obj })
 * return {
 *    questions, // Array of questions
 *    attributes: obj.data.attributes, // checked values
 *    visitCreator: object with visit creator
 * }
 *
 * @param {object} obj
 *
 * @return {object}
 */
export const convertVisitJSON = (obj) => {
  const getEntityData = (entity, acc) => {
    const currentValue = getCurrentValue(rootAttributes, entity.name);
    switch (entity.type) {
      case INPUT_TYPES.FILE: {
        if (!currentValue) {
          entity.value = null;
        } else {
          const file = Array.isArray(currentValue) && currentValue[0];
          const filePath = currentValue?.file_path || file?.content || currentValue || null;
          /* eslint-disable no-unused-vars */
          const [fileType, ...fileName] = currentValue?.file_name?.split('.').reverse() || [];

          const fileFormat = getFileFormat(entity?.acceptedTypes, fileType);

          entity.value = [{ name: currentValue?.file_name, content: filePath, type: fileFormat }];
          acc.push({ name: currentValue?.file_name, content: filePath, type: fileFormat });
        }
        return acc;
      }

      case INPUT_TYPES.CHECKBOX:
      case INPUT_TYPES.RADIOGROUP: {
        if (entity) {
          entity.value = currentValue;
          entity.title = entity.title.replace('*Required*', '');
          acc.push(entity);
        }

        const emptyValue = {
          text: 'No response',
          label: 'No response',
          value: 'null',
          name: entity.name,
        };
        const options = entity.choices.map((innerItem) => {
          const newOption = {
            ...innerItem,
            label: innerItem.text,
            name: entity.name,
          };
          // Convert string values to boolean for radiobuttonGroup
          if (innerItem.value === 'true') {
            return {
              ...newOption,
              value: true,
            };
          }
          if (innerItem.value === 'false') {
            return {
              ...newOption,
              value: false,
            };
          }
          if (innerItem.value === 'null') {
            return {
              ...newOption,
              value: 'null',
            };
          }
          return newOption;
        });
        if (options.length === 2) {
          // eslint-disable-next-line no-param-reassign
          entity.choices = [...options, emptyValue];
        } else {
          // eslint-disable-next-line no-param-reassign
          entity.choices = options;
        }
        // eslint-disable-next-line no-param-reassign
        entity.value = currentValue === null ? 'null' : currentValue;
        entity.title = entity.title.replace('*Required*', '');
        acc.push(entity);
        return acc;
      }

      case INPUT_TYPES.DROPDOWN: {
        const options = entity.choices.map((innerItem) => {
          const newOption = {
            ...innerItem,
            label: innerItem.text,
            name: entity.name,
          };
          return newOption;
        });
        // eslint-disable-next-line no-param-reassign
        entity.choices = [...options];
        // eslint-disable-next-line no-param-reassign
        entity.value = currentValue;
        acc.push(entity);
        return acc;
      }

      case INPUT_TYPES.HTML: {
        return acc;
      }

      default:
        entity.value = currentValue;
        acc.push(entity);
        return acc;
    }
  };

  const rootAttributes = obj.data.attributes;
  const questions = getDataFromInclude(obj.included, 'Form')?.attributes.form_json.pages.reduce((acc, item) => {
    item.questions?.forEach((question) => getEntityData(question, acc));
    item.elements?.forEach((element) => getEntityData(element, acc));
    return acc;
  }, []);

  const questionAttributes = questions.reduce((acc, item) => {
    switch (item.type) {
      case INPUT_TYPES.RADIOGROUP: {
        if (item.choices.length === 3 && item.choices.some(({ value }) => typeof value === 'boolean')) {
          // radiobuttonGroup
          acc[item.name] = item.value === false ? false : item.value || null;
        } else {
          // select
          acc[item.name] =
            item.value === undefined
              ? null
              : item.choices.find(({ value }) => value === item.value?.toString()) || null;
        }
        return acc;
      }

      case INPUT_TYPES.DROPDOWN: {
        acc[item.name] =
          item.value === undefined ? null : item.choices.find(({ value }) => value === item.value?.toString()) || null;
        return acc;
      }

      case INPUT_TYPES.FILE: {
        if (item.value) {
          acc[item.name] = item.value;
          return acc;
        }
        if (item.data[0]?.data_url || item.data[0]?.file_path) {
          acc[item.data[0]?.name] = item.data;
        }
        return acc;
      }

      case INPUT_TYPES.CHECKBOX: {
        if (!item.value) {
          acc[item.name] = [];
          return acc;
        }
        const newValue = item.value.map((itemValue) => {
          return item.choices.find(({ value }) => value === itemValue);
        });
        acc[item.name] = newValue;
        return acc;
      }

      case INPUT_TYPES.TEXT: {
        if (item.inputType === 'date') {
          acc[item.name] = item.value ? addTimezoneToDate(item.value) : '';
          return acc;
        }
        acc[item.name] = item.value || '';
        return acc;
      }

      default: {
        acc[item.name] = item.value || '';
        return acc;
      }
    }
  }, {});

  const attributes = { ...rootAttributes, ...questionAttributes };
  const visitCreator = getDataFromInclude(obj.included, 'User', obj.data.relationships.user.data.id);

  return {
    questions,
    attributes,
    visitCreator: {
      ...visitCreator.attributes,
      id: visitCreator.id,
    },
  };
};
