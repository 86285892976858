import styled from 'styled-components';
import { UiColors } from 'assets/styles/old/global';

export const FooterContainer = styled.div`
  min-height: 52px;
  background-color: ${UiColors.navyColor};
  display: flex;
  align-items: center;
  text-align: center;
`;

export const FooterContent = styled.p`
  width: 100%;
  font-size: 14px;
  color: ${UiColors.peachColor};

  a {
    color: ${UiColors.peachColor};
    text-decoration: underline;
  }
`;
