import styled from 'styled-components';
import { DEVICES } from '_constants';
import { Link } from 'react-router-dom';
import { UiColors } from 'assets/styles/old/global';

export const Card = styled.article`
  padding: 15px;
  border: 4px solid #f0eae8;
  background: var(--white);
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  @media ${DEVICES.tablet} {
    padding: 15px;
  }
`;

export const IconWrapper = styled.div`
  width: 66px;
  height: 66px;
  border-radius: 50%;
  background: transparent;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: start;

  @media ${DEVICES.tablet} {
    width: 52px;
    height: 52px;
  }
`;

export const Icon = styled.img`
  width: 38px;
  height: 38px;

  @media ${DEVICES.tablet} {
    width: 28px;
    height: 28px;
  }
`;

export const CardTitle = styled.h2`
  font-size: 16px;
  line-height: 1.17;
  font-weight: 500;
  margin-bottom: 12px;
`;

export const CardDescription = styled.p`
  font-size: 14px;
  line-height: 1.5;
  color: var(--dim-grey);
  margin-bottom: 12px;
`;

export const CardLink = styled(Link)`
  margin-top: auto;
  min-width: 112px;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  color: ${UiColors.forestColor};
  background: ${UiColors.mangoColor};
  text-align: center;

  :hover {
    filter: saturate(2);
  }
`;
