import styled from 'styled-components';
import { DEVICES } from '_constants';

export const Wrapper = styled.header`
  margin-bottom: 25px;
`;

export const OnlineWrapper = styled.div`
  margin-top: 15px;
`;

export const TopWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const TitleCol = styled.div`
  > a {
    margin-bottom: 15px;
  }
`;

export const Title = styled.h1`
  font-size: 22px;
  font-weight: 500;
`;

export const SubTitle = styled.p`
  font-size: 14px;
  color: var(--dim-grey);
  margin-top: 15px;
`;

export const ButtonWrap = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: baseline;

  @media screen and (${DEVICES.tablet}) {
    flex-flow: column;

    button {
      margin-bottom: 5px;
    }
  }

  > button {
    min-width: 102px;
    padding: 0 20px;

    :not(:last-of-type) {
      margin-right: 20px;
    }
  }
`;

export const Info = styled.div`
  flex: 1 0 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: baseline;

  @media screen and (${DEVICES.mobileXL}) {
    flex-flow: column;
  }
`;

export const P = styled.p`
  max-width: 45%;
  width: 100%;
  font-size: 16px;
  margin-bottom: 15px;

  @media screen and (${DEVICES.mobileXL}) {
    max-width: 100%;
  }

  a {
    font-weight: 500;
    text-decoration: underline;
    color: #5968b2;

    &:hover {
      text-decoration: none;
    }
  }
`;

export const Details = styled.span`
  font-weight: 500;
`;
