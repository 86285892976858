import styled from 'styled-components';

export const MenuContainer = styled.nav.attrs(() => ({
  ariaRequired: true,
}))`
  width: 100%;
  height: 100%;
  margin-left: 38px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;

  @media (max-width: 1235px) {
    margin-left: 10px;
  }
`;
