import styled from 'styled-components';

export const Wrapper = styled.div`
  .BaseSelect {
    &__control {
      border-radius: 0;
      width: 100%;
    }

    &__indicator-separator {
      display: none;
    }

    &__menu {
      border-radius: 0;
      bottom: 100%;
      top: auto;

      &-list {
        max-height: 120px;
      }
    }
  }
`;
