import styled from 'styled-components';
import { DEVICES } from '_constants';
import { UiColors } from 'assets/styles/old/global';
import { Wrapper as MobileFilterView } from './devices/MobileFilterView/styles';
import { Wrapper as DesktopFilterView } from './devices/DesktopFilterView/styles';

export const Sidebar = styled.div`
  height: 100%;
  width: 300px;
  min-width: 200px;
  border-right: 1px solid var(--whisper);
`;

export const SideBarHeader = styled.div.attrs(() => ({
  ariaRequired: true,
}))`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  color: var(--dark);
  height: 64px;
  display: flex;
  align-items: center;
  padding-left: 23px;
  border-bottom: 1px solid var(--whisper);
`;

export const SidebarMenuList = styled.ul.attrs(() => ({
  ariaRequired: true,
  role: 'menu',
}))`
  margin: 0;
  padding: 0;
`;

export const SidebarMenuItem = styled.li.attrs(() => ({
  ariaRequired: true,
  role: 'menuitem',
}))`
  display: flex;
  align-items: center;
  height: 42px;
  background-color: ${({ isActive }) => (isActive ? 'var(--grey-smoke)' : 'var(--white)')};
  padding-left: 23px;
  cursor: pointer;
  border-bottom: 1px solid var(--whisper);
`;

export const MainContent = styled.div.attrs(() => ({
  ariaRequired: true,
}))`
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: auto 1fr 68px;
  grid-template-columns: 100%;

  @media ${DEVICES.tablet} {
    grid-template-rows: none;
    grid-template-columns: none;
    display: flex;
    flex-direction: column;
    width: 100vw;
  }
`;

export const FilterContent = styled.div.attrs(() => ({
  ariaRequired: true,
}))`
  width: 100%;
  overflow-y: auto;
`;

export const FilterFooter = styled.div.attrs(() => ({
  ariaRequired: true,
}))`
  position: absolute;
  bottom: 20px;
  width: calc(100% - 308px);
  padding: 15px 15px 0 15px;

  @supports (padding: 15px) {
    padding: 20px 15px 0 15px;
  }
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 0 -4px 3px var(--black-two04);
  border-radius: 0 0 0 4px;
  box-sizing: border-box;
  @media ${DEVICES.tablet} {
    position: static;
  }
`;

const FilterButton = styled.button.attrs(() => ({
  ariaRequired: true,
  ariaPressed: 'false',
}))`
  color: white;
  background-color: transparent;
  width: 88px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  border: none;
`;

export const DarkButton = styled(FilterButton)`
  background-color: ${UiColors.mangoColor};
  color: ${UiColors.forestColor};
  margin: 0 10px 0 0;

  &:focus {
    border: 2px solid var(--focus) !important;
    outline-color: var(--focus) !important;
  }
`;

export const DangerButton = styled(FilterButton)`
  background-color: ${UiColors.forestColor};
  color: ${UiColors.peachColor};
`;

export const MobileTitle = styled.div.attrs(() => ({
  ariaRequired: true,
}))`
  display: none;
  font-size: 20px;
  line-height: 22px;
  padding: 0 18px;
  box-sizing: border-box;
  margin: 17px 0 0;

  @media ${DEVICES.tablet} {
    display: block;
  }
`;

export const Wrapper = styled.div.attrs(() => ({
  ariaRequired: true,
  id: 'filter-body',
}))`
  height: 100vh;
  display: flex;
  display: grid;
  grid-template-columns: 300px 1fr;

  ${MobileFilterView} {
    display: none;
  }

  @media ${DEVICES.tablet} {
    grid-template-columns: none;
    display: block;
    height: 100%;

    ${FilterContent} {
      height: 100%;
      overflow: visible;
    }

    ${MobileFilterView} {
      display: block;
    }

    ${FilterFooter} {
      justify-content: center;
      background: white;
      padding: 10px;
      z-index: 4;
      width: 100%;

      ${FilterButton} {
        width: 100%;
      }
    }

    ${Sidebar},
    ${DesktopFilterView} {
      display: none;
    }
  }

  .react-datepicker-popper {
    @media ${DEVICES.laptopL} {
      position: fixed !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
    }
  }

  .react-datepicker {
    &__triangle {
      @media ${DEVICES.laptopL} {
        display: none;
      }
    }
  }
`;
