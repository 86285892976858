import React, { useCallback, useReducer, useEffect, useState, useRef } from 'react';
import { AuthEntity, UserEntity } from '_entities';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PageContainer } from 'modules';
import queryString from 'query-string';
import { Loader } from 'ui-kit';
import { LoginModal } from 'modules/Modals';
import { BUTTON_TYPES, INPUT_TYPE, URLS } from '_constants';
import { formInitialState, FORM_ACTION_TYPES, formNames } from './constants';
import { formReducer, init } from './_utils';
import * as Styled from './styles';

const ACCOUNT = window.location.host.split('.')[0];

const { signIn } = AuthEntity.actions;
const { getSubdomain } = UserEntity.selectors;

function Login() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formState, formDispatch] = useReducer(formReducer, formInitialState, init);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const emailInput = useRef();
  const { isValidLink } = AuthEntity.actions;
  const subDomains = useSelector(getSubdomain);

  const { code } = queryString.parse(location.search);

  // Checking is valid reset password link
  useEffect(() => {
    if (typeof code === 'string') {
      setIsLoading(true);
      sessionStorage.setItem('code', code);
      (async function isLinkValid() {
        try {
          await dispatch(isValidLink(code));
          setIsModalOpen(true);
        } catch (err) {
          console.log(err);
          history.push(URLS.linkExpiration);
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [code, dispatch, history]);

  useEffect(() => {
    if (!subDomains.includes(ACCOUNT) && subDomains.length > 0) {
      history.push(URLS.notFound);
    }
  }, [subDomains, history]);

  useEffect(() => emailInput.current.focus(), []);

  const handleChange = useCallback(({ target: { name, value } }) => {
    formDispatch({ type: FORM_ACTION_TYPES.ENTER_DATA, name, payload: value });
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!formState?.canBeSubmitted) {
        Object.keys(formNames).forEach((name) => {
          formDispatch({
            type: FORM_ACTION_TYPES.VALIDATE_DATA,
            name,
            payload: formState.data[name],
          });
        });
        return;
      }
      try {
        const data = {
          username: formState.data.email,
          password: formState.data.password,
        };
        await dispatch(signIn(data));
        history.push(URLS.dashboard);
      } catch (err) {
        console.log(err);
        formDispatch({ type: FORM_ACTION_TYPES.INVALID_LOGIN });
      }
    },
    [dispatch, formState, history],
  );

  const handleModalClose = useCallback(() => setIsModalOpen(false));

  return (
    <PageContainer preventNotificationDismiss>
      {isLoading && <Loader />}
      <Styled.Wrapper>
        <Styled.FormWrapper onSubmit={handleSubmit}>
          <Styled.Title>Log in</Styled.Title>
          <Styled.LoginInput
            name={formNames.email}
            value={formState.data.email}
            error={formState.errors.email}
            type={INPUT_TYPE.EMAIL}
            floatingLabel
            title="Email address"
            onChange={handleChange}
            ref={emailInput}
          />
          <Styled.LoginInput
            name={formNames.password}
            value={formState.data.password}
            error={formState.errors.password}
            type={INPUT_TYPE.PASSWORD}
            floatingLabel
            title="Password"
            onChange={handleChange}
          />
          <Link to={URLS.forgotPassword}>Forgot password?</Link>
          <Styled.SubmitButton text="Log in" variant={BUTTON_TYPES.DARK} />
        </Styled.FormWrapper>
      </Styled.Wrapper>
      {isModalOpen && <LoginModal isOpen={isModalOpen} onClose={handleModalClose} />}
    </PageContainer>
  );
}

export default Login;
