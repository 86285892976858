import React from 'react';
import PropTypes from 'prop-types';
import { TabOrganizer } from 'ui-kit';
import * as Styled from './old.styles';

// Component is outdated use { Tabs } from 'modules' instead;
function TableTabsWrapper({
  hasTabs,
  amount,
  tableComponent,
  tabOptions,
  activeTab,
  onChangeTabs,
  mapComponent,
  isManageAssignment,
  hasBorders,
}) {
  return (
    <Styled.Wrapper isManageAssignment={isManageAssignment}>
      <Styled.TabsPanelWrapper className="table-info" hasBorders={hasBorders}>
        {hasTabs && (
          <TabOrganizer
            tabsTitle={tabOptions?.map((item) => item.value)}
            selectedValue={tabOptions?.find((item) => item.id === activeTab).value}
            useActiveTab={onChangeTabs}
            borderLeft
            isBorder
            minWidth="162px"
            minHeight="40px"
            isMap="162px"
            isMargin="0 0"
            borderColor="#E0E0E0;"
            inputId="mapInputId"
          />
        )}
        {(amount || amount === 0) && (
          <Styled.AmountText>{`${amount} items ${isManageAssignment ? '(retailers)' : ''}`}</Styled.AmountText>
        )}
      </Styled.TabsPanelWrapper>
      {hasTabs ? (
        <Styled.TabsContent>
          <Styled.ComponentWrapper className={activeTab === 'list' ? 'active' : ''}>
            {tableComponent}
          </Styled.ComponentWrapper>
          <Styled.ComponentWrapper className={activeTab === 'map' ? 'active' : ''}>
            <Styled.MapWrapper>{mapComponent}</Styled.MapWrapper>
          </Styled.ComponentWrapper>
        </Styled.TabsContent>
      ) : (
        tableComponent
      )}
    </Styled.Wrapper>
  );
}

TableTabsWrapper.propTypes = {
  hasTabs: PropTypes.bool,
  amount: PropTypes.string.isRequired,
  tableComponent: PropTypes.node.isRequired,
  tabOptions: PropTypes.array,
  activeTab: PropTypes.number,
  onChangeTabs: PropTypes.func,
  mapComponent: PropTypes.node,
  isManageAssignment: PropTypes.bool,
  hasBorders: PropTypes.bool,
};

TableTabsWrapper.defaultProps = {
  hasTabs: false,
  mapComponent: null,
  tabOptions: [],
  activeTab: 1,
  onChangeTabs: () => {},
  isManageAssignment: false,
  hasBorders: false,
};

export default TableTabsWrapper;
