import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Footer from 'modules/Footer';
import Header from 'modules/Header';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { ProgressBar } from 'ui-kit';
import { notification } from 'utils/services';
import { saveIdToLocalStorage, isIdInLocalStorage } from 'utils/custom';
import { AuthEntity, RetailerEntity, UserEntity } from '_entities';

import * as Styled from './styles';

const { getUsersImportInfo, getRetailersImportInfo, getAuthenticatedUser } = AuthEntity.selectors;
const { getRetailersImportProgress, resetRetailerImportData, setRetailerToastId } = RetailerEntity.actions;
const { getUsersImportProgress, resetUserImportInfo, setUserToastId } = UserEntity.actions;
const { getLastRetailerImport, getRetailerToastId } = RetailerEntity.selectors;
const { getLastUserImport, getUserToastId } = UserEntity.selectors;

const PROGRESS_STATES = ['VALIDATION_FAILED', 'SUCCESS', 'FAILED'];

function PageContainer({ children, preventNotificationDismiss }) {
  const isAuthenticated = useSelector(getAuthenticatedUser);
  const retailersImportInfo = useSelector(getRetailersImportInfo);
  const usersImportInfo = useSelector(getUsersImportInfo);
  const lastRetailerImport = useSelector(getLastRetailerImport);
  const lastUserImport = useSelector(getLastUserImport);
  const retailerToastId = useSelector(getRetailerToastId);
  const userToastId = useSelector(getUserToastId);
  const [isUserProgressOpen, setIsUserProgressOpen] = useState(!isIdInLocalStorage(usersImportInfo?.data));
  const [isRetailerProgressOpen, setIsRetailerProgressOpen] = useState(!isIdInLocalStorage(retailersImportInfo?.data));
  let userIntervalId = 0;
  let retailerIntervalId = 0;
  const dispatch = useDispatch();

  const handleCloseUserProgressBar = async (id, toastId) => {
    if (!id) return;
    if (id) saveIdToLocalStorage(+id);
    setIsUserProgressOpen(false);
    toast.dismiss(toastId || userToastId);
    await dispatch(resetUserImportInfo());
  };

  const handleCloseRetailerProgressBar = async (id, toastId) => {
    if (!id) return;
    if (id) saveIdToLocalStorage(+id);
    setIsRetailerProgressOpen(false);
    toast.dismiss(toastId || retailerToastId);
    await dispatch(resetRetailerImportData());
  };

  const checkImportStatus = async (getImportProgress, setIsProgressOpen, importType) => {
    try {
      const response = await dispatch(getImportProgress());
      const dataArray = response?.data || [];
      const dataArrayIds = dataArray.map(({ id }) => Number(id));
      const lastImportIndex = dataArrayIds.indexOf(Math.max(...dataArrayIds));
      const attributes = dataArray[lastImportIndex]?.attributes || {};
      const lastImportId = importType === 'user' ? lastUserImport?.id : lastRetailerImport?.id;
      const id = dataArrayIds[lastImportIndex] || lastImportId;

      if (PROGRESS_STATES.includes(attributes?.status)) {
        clearInterval(importType === 'user' ? userIntervalId : retailerIntervalId);
        const toastId = notification.importStatus({
          closeProgressBar: importType === 'user' ? handleCloseUserProgressBar : handleCloseRetailerProgressBar,
          status: attributes.status,
          filename: attributes?.filename,
          id,
          type: importType,
        });
        if (importType === 'user') {
          await dispatch(setUserToastId(toastId));
        } else {
          await dispatch(setRetailerToastId(toastId));
        }
      }
      setIsProgressOpen(true);
    } catch (error) {
      console.error('Error checking import status:', error);
    }
  };

  useEffect(() => {
    if (
      !isEmpty(usersImportInfo?.data) &&
      !isIdInLocalStorage(usersImportInfo?.data) &&
      isEmpty(lastUserImport) &&
      isAuthenticated
    ) {
      checkImportStatus(getUsersImportProgress, setIsUserProgressOpen, 'user');
    }
    if (
      !isEmpty(retailersImportInfo?.data) &&
      !isIdInLocalStorage(retailersImportInfo?.data) &&
      isEmpty(lastRetailerImport) &&
      isAuthenticated
    ) {
      checkImportStatus(getRetailersImportProgress, setIsRetailerProgressOpen, 'retailer');
    }
  }, [usersImportInfo?.data?.length, retailersImportInfo?.data?.length]);

  useEffect(() => {
    if (!isEmpty(lastUserImport) && !PROGRESS_STATES.includes(lastUserImport?.attributes?.status) && isAuthenticated) {
      userIntervalId = setInterval(
        () => checkImportStatus(getUsersImportProgress, setIsUserProgressOpen, 'user'),
        5000,
      );
    }
    if (
      !isEmpty(lastRetailerImport) &&
      !PROGRESS_STATES.includes(lastRetailerImport?.attributes?.status) &&
      isAuthenticated
    ) {
      retailerIntervalId = setInterval(
        () => checkImportStatus(getRetailersImportProgress, setIsRetailerProgressOpen, 'retailer'),
        5000,
      );
    }
    return () => {
      clearInterval(retailerIntervalId);
      clearInterval(userIntervalId);
    };
  }, [lastRetailerImport, lastUserImport]);

  const handleResetImportInfo = async (retailerInterval, userInterval) => {
    try {
      if (!preventNotificationDismiss) {
        toast.dismiss();
      }
      await dispatch(resetUserImportInfo());
      await dispatch(resetRetailerImportData());
      clearInterval(retailerInterval);
      clearInterval(userInterval);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      handleResetImportInfo(retailerIntervalId, userIntervalId);
    }
    return () => {
      if (!isAuthenticated) {
        handleResetImportInfo(retailerIntervalId, userIntervalId);
      }
    };
  }, [isAuthenticated]);

  return (
    <Styled.Wrapper id="html-canvas">
      <Header />
      {isRetailerProgressOpen && isAuthenticated && (
        <ProgressBar importInfo={lastRetailerImport} onClose={handleCloseRetailerProgressBar} />
      )}
      {isUserProgressOpen && isAuthenticated && (
        <ProgressBar importInfo={lastUserImport} onClose={handleCloseUserProgressBar} />
      )}
      <Styled.BodyContainer>{children}</Styled.BodyContainer>
      <Footer />
    </Styled.Wrapper>
  );
}

PageContainer.propTypes = {
  preventNotificationDismiss: PropTypes.bool,
};

PageContainer.defaultProps = {
  preventNotificationDismiss: false,
};

export default PageContainer;
