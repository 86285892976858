/* eslint-disable */
// a simple control to display a logo and credits in the corner of the map, with some neat interactive behavior
// in Leaflet tradition, a shortcut method is also provided, so you may use either version:
//     new L.CreditsControl(options)
//     L.controlCredits(options)
L.biglayerpanel = function (options) {
    return new L.Control.BigLayerPanel(options);
};

L.Control.BigLayerPanel = L.Control.extend({
    options: {
        position: 'topleft',
        // our Authorization session token
        token: '',
        // custom code to sync this to the Retailers filter and Venues filter
        RETAILERSETID: 0,
        VENUESETID: 0,
        // custom code to load Other Boundaries list & Demographics polygon options, from Account info endpoint
        calculableBoundaryLayers: [],
        // specific to this project: things with `id` will be scrolled-to when tab-focused
        controlid: null,
    },
    initialize: function(options) {
        L.setOptions(this, options);
    },
    onAdd: function (map) {
        const control = this;
        this._map = map;
        this._container = L.DomUtil.create('div', 'leaflet-biglayerpanel-control leaflet-control');

        // lay out our HTML
        // then query out the various links and DIVs so we can attach event handlers
        const html_calculablelayers = this.options.calculableBoundaryLayers.map(function (layerinfo) {
            return `
            <div class="leaflet-biglayerpanel-layer" data-layer-id="calcpoly-${layerinfo.slug}" data-layer-stacking="100">
                <div class="leaflet-biglayerpanel-layer-left">
                    <div class="checkbox-item">
                        <input type="checkbox" name="calcpolybounds" value="${layerinfo.slug}" id="leaflet-biglayerpanel-layer-calcpoly-${layerinfo.slug}" />
                        <label tabindex="0" for="leaflet-biglayerpanel-layer-calcpoly-${layerinfo.slug}"><div class="leaflet-biglayerpanel-layer-swatch leaflet-biglayerpanel-layer-swatch-line" style="background-color: ${layerinfo.bordercolor};"></div> ${layerinfo.plural}</label>
                    </div>
                </div>
                <div class="leaflet-biglayerpanel-layer-right" style="white-space: nowrap; text-align: left;">
                    <div class="checkbox-item">
                        <input type="checkbox" name="calcpolylabels" value="${layerinfo.slug}" id="leaflet-biglayerpanel-layer-calcpoly-${layerinfo.slug}-labels" />
                        <label tabindex="0" for="leaflet-biglayerpanel-layer-calcpoly-${layerinfo.slug}-labels" aria-label="Labels for ${layerinfo.plural}">Labels</label>
                    </div>
                </div>
            </div>
            `;
        }).join('');

        const html_demographicoptions = this.options.calculableBoundaryLayers.map(function (layerinfo) {
            return `<option value="${layerinfo.slug}">by ${layerinfo.singular}</option>`;
        });

        const selectbackgroundstyle = `background-image: url(${this.options.collapsedArrowIcon}); background-repeat: no-repeat; background-position: calc(100% - 5px);`;

        this._container.innerHTML = `
        <div class="leaflet-biglayerpanel-header" tabindex="0" aria-label="Open or close this panel" id="map-biglayerpanel-header">
            <button aria-expanded="true" class="leaflet-biglayerpanel-togglebutton" aria-controls="map-biglayerpanel-panel" id="map-biglayerpanel-togglebutton" tabindex="-1" aria-hidden="true">&times;</button>
            Map Features
        </div>

        <div class="leaflet-biglayerpanel-panel" id="map-biglayerpanel-panel" aria-labelledby="map-biglayerpanel-togglebutton">
            <div class="leaflet-biglayerpanel-accordion">

                <button aria-expanded="false" class="leaflet-biglayerpanel-accordion-title" id="map-biglayerpanel-accordion-title-retailers" aria-controls="map-biglayerpanel-accordion-panel-retailers">Retailers</button>
                <div class="leaflet-biglayerpanel-accordion-section collapsed" id="map-biglayerpanel-accordion-panel-retailers" aria-labelledby="map-biglayerpanel-accordion-title-retailers">
                    <fieldset>
                        <legend></legend>
                        <div class="leaflet-biglayerpanel-layer" data-layer-id="retailerpoints" data-layer-stacking="300">
                            <div class="leaflet-biglayerpanel-layer-left">
                                <div class="checkbox-item">
                                    <input type="checkbox" name="overlays" value="retailerpoints" id="leaflet-biglayerpanel-layer-retailerpoints" />
                                    <label tabindex="0" for="leaflet-biglayerpanel-layer-retailerpoints"><div class="leaflet-biglayerpanel-layer-swatch leaflet-biglayerpanel-layer-swatch-circle" style="background-color: #FF8080; border-color: #FF0000;"></div> Retailer Locations</label>
                                </div>
                            </div>
                            <div class="leaflet-biglayerpanel-layer-right">
                                &nbsp;
                            </div>
                        </div>
                        <div class="leaflet-biglayerpanel-layer" data-layer-id="retailersnearretailers" data-layer-stacking="301"><!-- immediately above retailerpoints 300 so the dots cover the dots -->
                            <div class="leaflet-biglayerpanel-layer-left">
                                <div class="checkbox-item">
                                    <input type="checkbox" name="overlays" value="retailersnearretailers" id="leaflet-biglayerpanel-layer-retailersnearretailers" />
                                    <label tabindex="0" for="leaflet-biglayerpanel-layer-retailersnearretailers">Retailers Near Other Retailers</label>
                                </div>

                                <div class="leaflet-biglayerpanel-layer-subpanel">
                                    Defined as within 500' of another retailer

                                    <br />

                                    <div class="leaflet-biglayerpanel-layer-swatch leaflet-biglayerpanel-layer-swatch-circle" style="background-color: #913fdf; border-color: #000000;"></div> Retailer is near another retailer
                                    <br/>
                                    <div class="leaflet-biglayerpanel-layer-swatch leaflet-biglayerpanel-layer-swatch-circle" style="background-color: #f28e36; border-color: #000000;"></div> Retailer is not near another retailer
                                    <br/>
                                    <div class="leaflet-biglayerpanel-layer-swatch leaflet-biglayerpanel-layer-swatch-circle" style="background-color: #B8B8B8; border-color: #000000;"></div> Out of business / Does not sell tobacco

                                    <br/>
                                    <em style="font-size: 85%;">Note: This view is only available at close-up levels.</em>
                                </div>
                            </div>
                            <div class="leaflet-biglayerpanel-layer-right">
                                &nbsp;
                            </div>
                        </div>
                        <div class="leaflet-biglayerpanel-layer" data-layer-id="retailersnearschools" data-layer-stacking="301"><!-- immediately above retailerpoints 300 so the dots cover the dots -->
                            <div>
                                <div class="checkbox-item">
                                    <input type="checkbox" name="overlays" value="retailersnearschools" id="leaflet-biglayerpanel-layer-retailersnearschools" />
                                    <label tabindex="0" for="leaflet-biglayerpanel-layer-retailersnearschools">
                                        Retailers Near Youth
                                    </label>
                                </div>

                                <div class="leaflet-biglayerpanel-layer-subpanel">
                                    Defined as within 1000' of a school

                                    <br />

                                    <div class="leaflet-biglayerpanel-layer-swatch leaflet-biglayerpanel-layer-swatch-circle" style="background-color: #913fdf; border-color: #000000;"></div> Retailer is near a school
                                    <br/>
                                    <div class="leaflet-biglayerpanel-layer-swatch leaflet-biglayerpanel-layer-swatch-circle" style="background-color: #f28e36; border-color: #000000;"></div> Retailer is not near a school

                                    <br/>
                                    <em style="font-size: 85%;">Note: This view is only available at close-up levels.</em>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>

                <button aria-expanded="false" class="leaflet-biglayerpanel-accordion-title" id="map-biglayerpanel-accordion-title-otherbounds" aria-controls="map-biglayerpanel-accordion-panel-otherbounds">Boundaries</button>
                <div class="leaflet-biglayerpanel-accordion-section collapsed" id="map-biglayerpanel-accordion-panel-otherbounds" aria-labelledby="map-biglayerpanel-accordion-title-otherbounds">
                    <fieldset>
                        <legend></legend>
                        ${html_calculablelayers}
                    </fieldset>
                </div>

                <button aria-expanded="false" class="leaflet-biglayerpanel-accordion-title" id="map-biglayerpanel-accordion-title-reference" aria-controls="map-biglayerpanel-accordion-panel-reference">Reference Layers</button>
                <div class="leaflet-biglayerpanel-accordion-section collapsed" id="map-biglayerpanel-accordion-panel-reference" aria-labelledby="map-biglayerpanel-accordion-title-reference">
                    <fieldset>
                        <legend></legend>
                        <div class="leaflet-biglayerpanel-layer" data-layer-id="venue-v2-publicschool" data-layer-stacking="320">
                            <div class="leaflet-biglayerpanel-layer-left">
                                <div class="checkbox-item">
                                    <input type="checkbox" name="overlays" value="venue-v2-publicschool" id="leaflet-biglayerpanel-layer-venue-v2-publicschool" />
                                    <label tabindex="0" for="leaflet-biglayerpanel-layer-venue-v2-publicschool"><div class="leaflet-biglayerpanel-layer-swatch leaflet-biglayerpanel-layer-swatch-image" style="background-image: url(${ this.options.maplegendimages['venue-publicschool'] });"></div> Public/Charter Schools</label>
                                </div>
                            </div>
                            <div class="leaflet-biglayerpanel-layer-right">
                                <div class="checkbox-item">
                                    <input type="checkbox" name="overlays" value="venue-v2-publicschool-labels" id="leaflet-biglayerpanel-layer-venue-v2-publicschool-labels" />
                                    <label tabindex="0" for="leaflet-biglayerpanel-layer-venue-v2-publicschool-labels">Labels</label>
                                </div>
                            </div>
                        </div>
                        <div class="leaflet-biglayerpanel-layer" data-layer-id="venue-v2-privateschool" data-layer-stacking="320">
                            <div class="leaflet-biglayerpanel-layer-left">
                                <div class="checkbox-item">
                                    <input type="checkbox" name="overlays" value="venue-v2-privateschool" id="leaflet-biglayerpanel-layer-venue-v2-privateschool" />
                                    <label tabindex="0" for="leaflet-biglayerpanel-layer-venue-v2-privateschool"><div class="leaflet-biglayerpanel-layer-swatch leaflet-biglayerpanel-layer-swatch-image" style="background-image: url(${ this.options.maplegendimages['venue-privateschool'] });"></div> Private Schools</label>
                                </div>
                            </div>
                            <div class="leaflet-biglayerpanel-layer-right">
                                <div class="checkbox-item">
                                    <input type="checkbox" name="overlays" value="venue-v2-privateschool-labels" id="leaflet-biglayerpanel-layer-venue-v2-privateschool-labels" />
                                    <label tabindex="0" for="leaflet-biglayerpanel-layer-venue-v2-privateschool-labels">Labels</label>
                                </div>
                            </div>
                        </div>
                        <div class="leaflet-biglayerpanel-layer" data-layer-id="venue-v2-park" data-layer-stacking="50">
                            <div class="leaflet-biglayerpanel-layer-left">
                                <div class="checkbox-item">
                                    <input type="checkbox" name="overlays" value="venue-v2-park" id="leaflet-biglayerpanel-layer-venue-v2-park" />
                                    <label tabindex="0" for="leaflet-biglayerpanel-layer-venue-v2-park"><div class="leaflet-biglayerpanel-layer-swatch leaflet-biglayerpanel-layer-swatch-image" style="background-image: url(${ this.options.maplegendimages['venue-park'] });"></div> Parks</label>
                                </div>
                            </div>
                            <div class="leaflet-biglayerpanel-layer-right">
                                <div class="checkbox-item">
                                    <input type="checkbox" name="overlays" value="venue-v2-park-labels" id="leaflet-biglayerpanel-layer-venue-v2-park-labels" />
                                    <label tabindex="0" for="leaflet-biglayerpanel-layer-venue-v2-park-labels">Labels</label>
                                </div>
                            </div>
                        </div>
                        <div class="leaflet-biglayerpanel-layer" data-layer-id="venue-v2-college" data-layer-stacking="320">
                            <div class="leaflet-biglayerpanel-layer-left">
                                <div class="checkbox-item">
                                    <input type="checkbox" name="overlays" value="venue-v2-college" id="leaflet-biglayerpanel-layer-venue-v2-college" />
                                    <label tabindex="0" for="leaflet-biglayerpanel-layer-venue-v2-college"><div class="leaflet-biglayerpanel-layer-swatch leaflet-biglayerpanel-layer-swatch-image" style="background-image: url(${ this.options.maplegendimages['venue-college'] });"></div> Colleges/Universities</label>
                                </div>
                            </div>
                            <div class="leaflet-biglayerpanel-layer-right">
                                <div class="checkbox-item">
                                    <input type="checkbox" name="overlays" value="venue-v2-college-labels" id="leaflet-biglayerpanel-layer-venue-v2-college-labels" />
                                    <label tabindex="0" for="leaflet-biglayerpanel-layer-venue-v2-college-labels">Labels</label>
                                </div>
                            </div>
                        </div>
                        <div class="leaflet-biglayerpanel-layer" data-layer-id="venue-v2-multihousing" data-layer-stacking="320">
                            <div class="leaflet-biglayerpanel-layer-left">
                                <div class="checkbox-item">
                                    <input type="checkbox" name="overlays" value="venue-v2-multihousing" id="leaflet-biglayerpanel-layer-venue-v2-multihousing" />
                                    <label tabindex="0" for="leaflet-biglayerpanel-layer-venue-v2-multihousing"><div class="leaflet-biglayerpanel-layer-swatch leaflet-biglayerpanel-layer-swatch-image" style="background-image: url(${ this.options.maplegendimages['venue-multihousing'] });"></div> Multi-Unit Housing</label>
                                </div>
                            </div>
                            <div class="leaflet-biglayerpanel-layer-right">
                                <div class="checkbox-item">
                                    <input type="checkbox" name="overlays" value="venue-v2-multihousing-labels" id="leaflet-biglayerpanel-layer-venue-v2-multihousing-labels" />
                                    <label tabindex="0" for="leaflet-biglayerpanel-layer-venue-v2-multihousing-labels">Labels</label>
                                </div>
                            </div>
                        </div>
                        <div class="leaflet-biglayerpanel-layer" data-layer-id="venue-v2-govbuilding" data-layer-stacking="320">
                            <div class="leaflet-biglayerpanel-layer-left">
                                <div class="checkbox-item">
                                    <input type="checkbox" name="overlays" value="venue-v2-govbuilding" id="leaflet-biglayerpanel-layer-venue-v2-govbuilding" />
                                    <label tabindex="0" for="leaflet-biglayerpanel-layer-venue-v2-govbuilding"><div class="leaflet-biglayerpanel-layer-swatch leaflet-biglayerpanel-layer-swatch-image" style="background-image: url(${ this.options.maplegendimages['venue-govbuilding'] });"></div> Government Buildings</label>
                                </div>
                            </div>
                            <div class="leaflet-biglayerpanel-layer-right">
                                <div class="checkbox-item">
                                    <input type="checkbox" name="overlays" value="venue-v2-govbuilding-labels" id="leaflet-biglayerpanel-layer-venue-v2-govbuilding-labels" />
                                    <label tabindex="0" for="leaflet-biglayerpanel-layer-venue-v2-govbuilding-labels">Labels</label>
                                </div>
                            </div>
                        </div>
                        <div class="leaflet-biglayerpanel-layer" data-layer-id="venue-v2-hospital" data-layer-stacking="320">
                            <div class="leaflet-biglayerpanel-layer-left">
                                <div class="checkbox-item">
                                    <input type="checkbox" name="overlays" value="venue-v2-hospital" id="leaflet-biglayerpanel-layer-venue-v2-hospital" />
                                    <label tabindex="0" for="leaflet-biglayerpanel-layer-venue-v2-hospital"><div class="leaflet-biglayerpanel-layer-swatch leaflet-biglayerpanel-layer-swatch-image" style="background-image: url(${ this.options.maplegendimages['venue-hospital'] });"></div> Hospitals/Clinics</label>
                                </div>
                            </div>
                            <div class="leaflet-biglayerpanel-layer-right">
                                <div class="checkbox-item">
                                    <input type="checkbox" name="overlays" value="venue-v2-hospital-labels" id="leaflet-biglayerpanel-layer-venue-v2-hospital-labels" />
                                    <label tabindex="0" for="leaflet-biglayerpanel-layer-venue-v2-hospital-labels">Labels</label>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>

                <button aria-expanded="false" class="leaflet-biglayerpanel-accordion-title" id="map-biglayerpanel-accordion-title-demographics" aria-controls="map-biglayerpanel-accordion-panel-demographics">Demographics</button>
                <div class="leaflet-biglayerpanel-accordion-section collapsed" id="map-biglayerpanel-accordion-panel-demographics" aria-labelledby="map-biglayerpanel-accordion-title-demographics">
                    <select class="leaflet-biglayerpanel-demographics-polytype" aria-label="Select an area type to show their demographics on the map" style="${selectbackgroundstyle}">
                        <option value="">Do not show demographics</option>
                        ${html_demographicoptions}
                    </select>

                    <fieldset>
                        <legend></legend>
                        <div class="leaflet-biglayerpanel-demographics-layer collapsed" data-demographics-polytype="pctblack">
                            <div class="radio-item">
                                <input type="radio" id="leaflet-biglayerpanel-demographics-polytype-pctblack" name="leaflet-biglayerpanel-demographics-polytype" value="pctblack" />
                                <label tabindex="0" for="leaflet-biglayerpanel-demographics-polytype-pctblack">Black</label>
                            </div>
                            <div class="leaflet-biglayerpanel-demographics-layer-legend">
                                <span class="leaflet-biglayerpanel-demographics-swatch q1" alt="color swatch for lowest"></span> <label>Lowest</label><br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q2" alt="color swatch for low"></span> <br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q3" alt="color swatch for medium"></span> <br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q4" alt="color swatch for high"></span> <br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q5" alt="color swatch for highest"></span> <label>Highest</label><br/>
                            </div>
                        </div>
                        <div class="leaflet-biglayerpanel-demographics-layer collapsed" data-demographics-polytype="pctamerind">
                            <div class="radio-item">
                                <input type="radio" id="leaflet-biglayerpanel-demographics-polytype-pctamerind" name="leaflet-biglayerpanel-demographics-polytype" value="pctamerind" />
                                <label tabindex="0" for="leaflet-biglayerpanel-demographics-polytype-pctamerind">Native American</label>
                            </div>
                            <div class="leaflet-biglayerpanel-demographics-layer-legend">
                                <span class="leaflet-biglayerpanel-demographics-swatch q1" alt="color swatch for lowest"></span> <label>Lowest</label><br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q2" alt="color swatch for low"></span> <br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q3" alt="color swatch for medium"></span> <br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q4" alt="color swatch for high"></span> <br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q5" alt="color swatch for highest"></span> <label>Highest</label><br/>
                            </div>
                        </div>
                        <div class="leaflet-biglayerpanel-demographics-layer collapsed" data-demographics-polytype="pctashawpi">
                            <div class="radio-item">
                                <input type="radio" id="leaflet-biglayerpanel-demographics-polytype-pctashawpi" name="leaflet-biglayerpanel-demographics-polytype" value="pctashawpi" />
                                <label tabindex="0" for="leaflet-biglayerpanel-demographics-polytype-pctashawpi">Asian or Pacific</label>
                            </div>
                            <div class="leaflet-biglayerpanel-demographics-layer-legend">
                                <span class="leaflet-biglayerpanel-demographics-swatch q1" alt="color swatch for lowest"></span> <label>Lowest</label><br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q2" alt="color swatch for low"></span> <br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q3" alt="color swatch for medium"></span> <br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q4" alt="color swatch for high"></span> <br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q5" alt="color swatch for highest"></span> <label>Highest</label><br/>
                            </div>
                        </div>
                        <div class="leaflet-biglayerpanel-demographics-layer collapsed" data-demographics-polytype="pctwhite">
                            <div class="radio-item">
                                <input type="radio" id="leaflet-biglayerpanel-demographics-polytype-pctwhite" name="leaflet-biglayerpanel-demographics-polytype" value="pctwhite" />
                                <label tabindex="0" for="leaflet-biglayerpanel-demographics-polytype-pctwhite">White</label>
                            </div>
                            <div class="leaflet-biglayerpanel-demographics-layer-legend">
                                <span class="leaflet-biglayerpanel-demographics-swatch q1" alt="color swatch for lowest"></span> <label>Lowest</label><br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q2" alt="color swatch for low"></span> <br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q3" alt="color swatch for medium"></span> <br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q4" alt="color swatch for high"></span> <br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q5" alt="color swatch for highest"></span> <label>Highest</label><br/>
                            </div>
                        </div>
                        <div class="leaflet-biglayerpanel-demographics-layer collapsed" data-demographics-polytype="pctotherrace">
                            <div class="radio-item">
                                <input type="radio" id="leaflet-biglayerpanel-demographics-polytype-pctotherrace" name="leaflet-biglayerpanel-demographics-polytype" value="pctotherrace" />
                                <label tabindex="0" for="leaflet-biglayerpanel-demographics-polytype-pctotherrace">Other Race/Ethnicity</label>
                            </div>
                            <div class="leaflet-biglayerpanel-demographics-layer-legend">
                                <span class="leaflet-biglayerpanel-demographics-swatch q1" alt="color swatch for lowest"></span> <label>Lowest</label><br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q2" alt="color swatch for low"></span> <br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q3" alt="color swatch for medium"></span> <br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q4" alt="color swatch for high"></span> <br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q5" alt="color swatch for highest"></span> <label>Highest</label><br/>
                            </div>
                        </div>
                        <div class="leaflet-biglayerpanel-demographics-layer collapsed" data-demographics-polytype="pcthisp">
                            <div class="radio-item">
                                <input type="radio" id="leaflet-biglayerpanel-demographics-polytype-pcthisp" name="leaflet-biglayerpanel-demographics-polytype" value="pcthisp" />
                                <label tabindex="0" for="leaflet-biglayerpanel-demographics-polytype-pcthisp">Hispanic</label>
                            </div>
                            <div class="leaflet-biglayerpanel-demographics-layer-legend">
                                <span class="leaflet-biglayerpanel-demographics-swatch q1" alt="color swatch for lowest"></span> <label>Lowest</label><br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q2" alt="color swatch for low"></span> <br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q3" alt="color swatch for medium"></span> <br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q4" alt="color swatch for high"></span> <br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q5" alt="color swatch for highest"></span> <label>Highest</label><br/>
                            </div>
                        </div>
                        <div class="leaflet-biglayerpanel-demographics-layer collapsed" data-demographics-polytype="pctyouth">
                            <div class="radio-item">
                                <input type="radio" id="leaflet-biglayerpanel-demographics-polytype-pctyouth" name="leaflet-biglayerpanel-demographics-polytype" value="pctyouth" />
                                <label tabindex="0" for="leaflet-biglayerpanel-demographics-polytype-pctyouth">Youth</label>
                            </div>
                            <div class="leaflet-biglayerpanel-demographics-layer-legend">
                                <span class="leaflet-biglayerpanel-demographics-swatch q1" alt="color swatch for lowest"></span> <label>Lowest</label><br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q2" alt="color swatch for low"></span> <br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q3" alt="color swatch for medium"></span> <br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q4" alt="color swatch for high"></span> <br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q5" alt="color swatch for highest"></span> <label>Highest</label><br/>
                            </div>
                        </div>
                        <div class="leaflet-biglayerpanel-demographics-layer collapsed" data-demographics-polytype="mhhinc">
                            <div class="radio-item">
                                <input type="radio" id="leaflet-biglayerpanel-demographics-polytype-mhhinc" name="leaflet-biglayerpanel-demographics-polytype" value="mhhinc" />
                                <label tabindex="0" for="leaflet-biglayerpanel-demographics-polytype-mhhinc">Income</label>
                            </div>
                            <div class="leaflet-biglayerpanel-demographics-layer-legend">
                                <span class="leaflet-biglayerpanel-demographics-swatch q1" alt="color swatch for highest"></span> <label>Highest</label><br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q2" alt="color swatch for high"></span> <br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q3" alt="color swatch for medium"></span> <br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q4" alt="color swatch for low"></span> <br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q5" alt="color swatch for lowest"></span> <label>Lowest</label><br/>
                            </div>
                        </div>
                        <div class="leaflet-biglayerpanel-demographics-layer collapsed" data-demographics-polytype="popdens">
                            <div class="radio-item">
                                <input type="radio" id="leaflet-biglayerpanel-demographics-polytype-popdens" name="leaflet-biglayerpanel-demographics-polytype" value="popdens" />
                                <label tabindex="0" for="leaflet-biglayerpanel-demographics-polytype-popdens">Population Density</label>
                            </div>
                            <div class="leaflet-biglayerpanel-demographics-layer-legend">
                                <span class="leaflet-biglayerpanel-demographics-swatch q1" alt="color swatch for lowest"></span> <label>Lowest</label><br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q2" alt="color swatch for low"></span> <br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q3" alt="color swatch for medium"></span> <br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q4" alt="color swatch for high"></span> <br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q5" alt="color swatch for highest"></span> <label>Highest</label><br/>
                            </div>
                        </div>
                        <div class="leaflet-biglayerpanel-demographics-layer collapsed" data-demographics-polytype="edattain">
                            <div class="radio-item">
                                <input type="radio" id="leaflet-biglayerpanel-demographics-polytype-edattain" name="leaflet-biglayerpanel-demographics-polytype" value="edattain" />
                                <label tabindex="0" for="leaflet-biglayerpanel-demographics-polytype-edattain">Educational Attainment</label>
                            </div>
                            <div class="leaflet-biglayerpanel-demographics-layer-legend">
                                <span class="leaflet-biglayerpanel-demographics-swatch q1" alt="color swatch for highest"></span> <label>Highest</label><br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q2" alt="color swatch for high"></span> <br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q3" alt="color swatch for medium"></span> <br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q4" alt="color swatch for low"></span> <br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q5" alt="color swatch for lowest"></span> <label>Lowest</label><br/>
                            </div>
                        </div>
                        <div class="leaflet-biglayerpanel-demographics-layer collapsed" data-demographics-polytype="sovulnindex">
                            <div class="radio-item">
                                <input type="radio" id="leaflet-biglayerpanel-demographics-polytype-sovulnindex" name="leaflet-biglayerpanel-demographics-polytype" value="sovulnindex" />
                                <label tabindex="0" for="leaflet-biglayerpanel-demographics-polytype-sovulnindex">Social Vulnerability Index</label>
                            </div>
                            <div class="leaflet-biglayerpanel-demographics-layer-legend">
                                <p style="font-size: 80%;">This statistic is shown at County level regardless of the selection above.</p>
                                <span class="leaflet-biglayerpanel-demographics-swatch q1" alt="color swatch for lowest"></span> <label>Lowest</label><br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q2" alt="color swatch for low"></span> <br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q3" alt="color swatch for medium"></span> <br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q4" alt="color swatch for high"></span> <br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q5" alt="color swatch for highest"></span> <label>Highest</label><br/>
                            </div>
                        </div>
                        <div class="leaflet-biglayerpanel-demographics-layer collapsed" data-demographics-polytype="lifeexpect">
                            <div class="radio-item">
                                <input type="radio" id="leaflet-biglayerpanel-demographics-polytype-lifeexpect" name="leaflet-biglayerpanel-demographics-polytype" value="lifeexpect" />
                                <label tabindex="0" for="leaflet-biglayerpanel-demographics-polytype-lifeexpect">Life Expectancy</label>
                            </div>
                            <div class="leaflet-biglayerpanel-demographics-layer-legend">
                                <p style="font-size: 80%;">This statistic is shown at County level regardless of the selection above.</p>
                                <span class="leaflet-biglayerpanel-demographics-swatch q1" alt="color swatch for highest"></span> <label>Highest</label><br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q2" alt="color swatch for high"></span> <br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q3" alt="color swatch for medium"></span> <br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q4" alt="color swatch for low"></span> <br/>
                                <span class="leaflet-biglayerpanel-demographics-swatch q5" alt="color swatch for lowest"></span> <label>Lowest</label><br/>
                            </div>
                        </div>
                    </fieldset>
                </div>

            </div><!--/accordion-->
        </div><!--/panel-->
        `;
    //     <div class="leaflet-biglayerpanel-demographics-layer collapsed" data-demographics-polytype="pcturban">
    //     <div class="radio-item">
    //         <input type="radio" id="leaflet-biglayerpanel-demographics-polytype-pcturban" name="leaflet-biglayerpanel-demographics-polytype" value="pcturban" />
    //         <label tabindex="0" for="leaflet-biglayerpanel-demographics-polytype-pcturban">Urban</label>
    //     </div>
    //     <div class="leaflet-biglayerpanel-demographics-layer-legend">
    //         <span class="leaflet-biglayerpanel-demographics-swatch q1" alt="color swatch for lowest"></span> <label>Rural</label><br/>
    //         <span class="leaflet-biglayerpanel-demographics-swatch q2" alt="color swatch for low"></span> <br/>
    //         <span class="leaflet-biglayerpanel-demographics-swatch q3" alt="color swatch for medium"></span> <br/>
    //         <span class="leaflet-biglayerpanel-demographics-swatch q4" alt="color swatch for high"></span> <br/>
    //         <span class="leaflet-biglayerpanel-demographics-swatch q5" alt="color swatch for highest"></span> <label>Urban</label><br/>
    //     </div>
    // </div>

        this._toggleheader = this._container.querySelector('div.leaflet-biglayerpanel-header');
        this._togglebutton = this._container.querySelector('button.leaflet-biglayerpanel-togglebutton');
        this._bigpanel = this._container.querySelector('div.leaflet-biglayerpanel-panel');
        this._headings = this._container.querySelectorAll('button.leaflet-biglayerpanel-accordion-title');
        this._sections = this._container.querySelectorAll('div.leaflet-biglayerpanel-accordion-section');
        this._layercheckboxes = this._container.querySelectorAll('input[type="checkbox"][name="overlays"]');
        this._calcpolyboundspickers = this._container.querySelectorAll('input[type="checkbox"][name="calcpolybounds"]');
        this._calcpolylabelspickers = this._container.querySelectorAll('input[type="checkbox"][name="calcpolylabels"]');
        this._checkboxlabels = this._container.querySelectorAll('label[for][tabindex]');
        this._demogpolytypepicker = this._container.querySelector('select.leaflet-biglayerpanel-demographics-polytype');
        this._demoglayers = this._container.querySelectorAll('div.leaflet-biglayerpanel-demographics-layer');
        this._demoglabels = this._container.querySelectorAll('div.leaflet-biglayerpanel-demographics-layer label');
        this._demogradios = this._container.querySelectorAll('div.leaflet-biglayerpanel-demographics-layer input[type="radio"]');

        // prepend to the buttons, the chevrons which we will also swap when the sections expand/collapse
        [...this._headings].forEach((heading) => {
            const arrow = L.DomUtil.create('img');
            arrow.src = this.options.collapsedArrowIcon;
            arrow.setAttribute('alt', '');
            arrow.setAttribute('aria-hidden', 'true');

            heading.insertBefore(arrow, heading.firstChild);
        });

        // attach event handlers
        this._toggleheader.addEventListener('click', () => {
            this.togglePanel();
        });
        this._toggleheader.addEventListener('keydown', (e) => {
            if (e.key == 'Enter') this._toggleheader.click();
        });

        this._headings.forEach((headinglink) => {
            headinglink.addEventListener('click', (e) => {
                const sectionid = e.target.getAttribute('aria-controls');
                this.toggleSection(sectionid);
            });
        });

        this._layercheckboxes.forEach((checkbox) => {
            checkbox.addEventListener('change', (e) => {
                const layerid = e.target.value;
                const checked = e.target.checked;
                this.toggleLayer(layerid, checked);
            });
        });

        this._checkboxlabels.forEach((cblabel) => {
            cblabel.addEventListener('keydown', (e) => { // labels already pass cicks to checkboxes, but not Space keypresses; catch those and make them click
                if (e.key == ' ') cblabel.click();
            });
        });

        this._calcpolyboundspickers.forEach((checkbox) => {
            checkbox.addEventListener('change', () => {
                this.updateCalculablePolygonBoundaryOverlay();
            });
        });

        this._calcpolylabelspickers.forEach((checkbox) => {
            checkbox.addEventListener('change', () => {
                this.updateCalculablePolygonLabelOverlay();
            });
        });

        this._demogpolytypepicker.addEventListener('change', () => {
            this.updateDemographicOverlay();
        });
        this._demogradios.forEach((radiobox) => {
            radiobox.addEventListener('change', () => {
                this.updateDemographicOverlay();
            });
        });

        // radiobox hack: up/down arrow keys fid the next option in this same radioset, and focus it
        this._demoglabels.forEach((dl) => {
            dl.addEventListener('keydown', (e) => {
                let next = e.target.closest('div.leaflet-biglayerpanel-demographics-layer').nextElementSibling;
                let prev = e.target.closest('div.leaflet-biglayerpanel-demographics-layer').previousElementSibling;
                if (! next || next.tagName != 'DIV' || ! next.classList.contains('leaflet-biglayerpanel-demographics-layer')) next = undefined;
                if (! prev || prev.tagName != 'DIV' || ! prev.classList.contains('leaflet-biglayerpanel-demographics-layer')) prev = undefined;

                let focusme;
                if (e.key == 'ArrowUp' && prev) focusme = prev.querySelector('label');
                else if (e.key == 'ArrowDown' && next) focusme = next.querySelector('label');

                if (focusme) {
                    e.stopPropagation();
                    e.preventDefault();
                    focusme.focus();
                    focusme.click();
                }
            });
        });

        // keep mouse events from falling through to the map: don't drag-pan or double-click the map on accident
        L.DomEvent.disableClickPropagation(this._container);
        L.DomEvent.disableScrollPropagation(this._container);

        // we use a special WMS handler which merges multiple layers into one request, to save on server load and transfer, and to improve label collision behavior
        // but the source doesn't sort the layer stacking; thus the hack below to sort them into the same sequence as their checkboxes (first checkbox = bottom of stack)
        // it also doesn't know that some layers should be clickable and some not, so override that too
        // see also handleWmsGetFeatureInfoResults()
        this._wmssource = L.WMS.source(this.options.wmsbaseurl, {
            version: '1.1.1', styles: '', format: 'image/png', transparent: 'true',
            identify: true,
            // identifyLayers: will be retroactively defined in the refresh below, because we hack in weird layers for sub-filtering
            feature_count: 5,  // max features to return; keep it low because it's shown in a popup in a small DIV
            info_format: 'application/vnd.ogc.gml',  // use XML so we can parse it; see showFeatureInfo()
        }).addTo(this._map);

        // turn on the statewide boundary, period
        this._wmssource.getLayer('universe').addTo(this._map);

        // add our custom Authorization header to the GetFeatureInfo requests, as they give away information
        // and use the GetFeatureInfo endpoint which is slightly different than the picture endpoint
        const getfeatureinfourl = this.options.getfeatureinfourl;
        this._wmssource.getFeatureInfo = function(point, latlng, layers, callback) {
            const params = this.getFeatureInfoParams(point, layers);
            const url = getfeatureinfourl + L.Util.getParamString(params, getfeatureinfourl);

            this.showWaiting();
            this.ajax(url, done);

            function done(result) {
                this.hideWaiting();
                var text = this.parseFeatureInfo(result, url);
                callback.call(this, latlng, text);
            }
        };
        this._wmssource.ajax = function (url, callback) {
            const token = localStorage.getItem('Authorization');
            doxhr.call(this, url, token, callback);

            function doxhr (url, token, callback) {
                const context = this;

                const request = new XMLHttpRequest();
                request.onreadystatechange = function () {
                    if (request.readyState === 4) {
                        if (request.status === 200) callback.call(context, request.responseText);
                        else callback.call(context, "error");
                    }
                };
                request.open('GET', url);
                request.setRequestHeader('Authorization', `Token ${token}`);
                request.send();
            }
        };

        this._wmssource.refreshOverlay = function () {  // monkey-patch into an existing control, so we need to use function() syntax and lose "this"
            if (!this._map) return;

            // fetch the WMS layer names to merge together, from the collected sublayers
            // sublayers are determined by:
            // the plain layer checkboxes per toggleLayer()
            // a selected boundary and labels from Other Boundaries, adds layer and the CALCPOLYBOUNDSLUGS and CALCPOLYLABELSLUGS params
            // the demographic selection UI per updateDemographicOverlay() which adds a layer and the DEMOGPOLY param
            // see getLayerZIndex() for some hacks to stack some of these pseudo-layers into position
            let wmslayerids = Object.keys(this._subLayers);

            const calcpolyboundschoices = control.getCalculablePolygonBoundaryOverlaySelections();
            const calcpolylabelschoices = control.getCalculablePolygonLabelOverlaySelections();
            if (calcpolyboundschoices.length) {
                wmslayerids.push('calcpoly-v2-boundary');
            }
            if (calcpolylabelschoices.length) {
                wmslayerids.push('calcpoly-v2-labels');
            }

            const demogchoice = control.getDemographicSelection();
            if (demogchoice) {
                const demoglayerid = `demog_v2_${demogchoice.demoglayer}`;
                wmslayerids.push(demoglayerid);
            }

            // if we are using unfiltered Retailers / Venues then replace the layer names to their unfiltered version
            if (control.options.RETAILERSETID == 0) {
                wmslayerids = wmslayerids.map(function (layerid) {
                    if (layerid === 'retailerpoints') return 'retailerpoints-all-single';
                    if (layerid === 'retailersnearretailers') return 'retailersnearretailers-all';
                    if (layerid === 'retailersnearschools') return 'retailersnearschools-all';
                    return layerid;
                });
            }
            if (control.options.VENUESETID == 0) {
                wmslayerids = wmslayerids.map(function (layerid) {
                    if (layerid === 'venue-v2-privateschool') return 'venue-v2-privateschool-all';
                    if (layerid === 'venue-v2-privateschool-labels') return 'venue-v2-privateschool-labels-all';
                    if (layerid === 'venue-v2-publicschool') return 'venue-v2-publicschool-all';
                    if (layerid === 'venue-v2-publicschool-labels') return 'venue-v2-publicschool-labels-all';
                    if (layerid === 'venue-v2-park') return 'venue-v2-park-all';
                    if (layerid === 'venue-v2-park-labels') return 'venue-v2-park-labels-all';
                    if (layerid === 'venue-v2-college') return 'venue-v2-college-all';
                    if (layerid === 'venue-v2-college-labels') return 'venue-v2-college-labels-all';
                    if (layerid === 'venue-v2-multihousing') return 'venue-v2-multihousing-all';
                    if (layerid === 'venue-v2-multihousing-labels') return 'venue-v2-multihousing-labels-all';
                    if (layerid === 'venue-v2-govbuilding') return 'venue-v2-govbuilding-all';
                    if (layerid === 'venue-v2-govbuilding-labels') return 'venue-v2-govbuilding-labels-all';
                    if (layerid === 'venue-v2-hospital') return 'venue-v2-hospital-all';
                    if (layerid === 'venue-v2-hospital-labels') return 'venue-v2-hospital-labels-all';
                    return layerid;
                });
            }

            // sort them by their stated stacking order; layers lacking data-layer-stacking= will be null and fall to start/bottom of stack
            wmslayerids.sort(function (p, q) {
                const pz = control.getLayerZIndex(p);
                const qz = control.getLayerZIndex(q);
                return pz - qz;
            });

            // done with picking the layers for WMS display
            // update the overlay, or remove it
            if (wmslayerids.length) {
                const params = {
                    layers: wmslayerids.join(","),
                    RETAILERSETID: control.options.RETAILERSETID,
                    VENUESETID: control.options.VENUESETID,
                    DEMOGPOLY: demogchoice ? demogchoice.polytype : '',
                    CALCPOLYBOUNDSLUGS: calcpolyboundschoices.length ? calcpolyboundschoices.map((x) => { return `'${x}'`; }).join(',') : '',
                    CALCPOLYLABELSLUGS: calcpolylabelschoices.length ? calcpolylabelschoices.map((x) => { return `'${x}'`; }).join(',') : '',
                };
                this._overlay.setParams(params);
                // see also caller's updateVenuesOnMap() which sets RETAILERSETID to filter retailers to match that external search
                this._overlay.addTo(this._map);
            }
            else {
                this._overlay.remove();
            }

            // post-work: GetFeatureInfo (click-query) target layer, the 1st Retailer / Venue layer they have visible
            // note that the RETAILERSETID==0 and VENUESETID==0 checks above changed the layer IDs being queried,
            // which effectively disables click-query for whichever one we're not filtering; sounds like a bug, but it's a feature
            // thus, click retailers when in "filtering retailers mode" and click venues when in "filtering venues mode"
            control._wmssource.options.identifyLayers = wmslayerids.filter(function (layerid) {
              return [
                'retailerpoints', 'retailersnearschools', 'retailersnearretailers',
                'venue-v2-park', 'venue-v2-publicschool', 'venue-v2-privateschool',
                'venue-v2-college', 'venue-v2-multihousing', 'venue-v2-govbuilding', 'venue-v2-hospital',
              ].indexOf(layerid) !== -1;
            });
        };
        this._wmssource.addSubLayer = function (name, donotredraw) {
            this._subLayers[name] = true;
            if (! donotredraw) this.refreshOverlay();
        };
        this._wmssource.removeSubLayer = function (name, donotredraw) {
            delete this._subLayers[name];
            if (! donotredraw) this.refreshOverlay();
        };

        this._wmssource.showFeatureInfo = (latlng, xmlstring) => {
            this.handleWmsGetFeatureInfoResults(latlng, xmlstring);
        };

        // all done; hand back the container DIV
        return this._container;
    },

    //
    // methods for expanding & collapsing the whole control
    //
    collapsePanel: function () {
        this._bigpanel.classList.add('collapsed');
        this._togglebutton.setAttribute('aria-expanded', 'false');
    },
    expandPanel: function () {
        this._bigpanel.classList.remove('collapsed');
        this._togglebutton.setAttribute('aria-expanded', 'true');
    },
    togglePanel: function () {
        const alreadyshowing = this.panelExpanded();
        if (alreadyshowing) this.collapsePanel();
        else this.expandPanel();
    },
    panelExpanded: function () {
        const alreadyshowing = ! this._bigpanel.classList.contains('collapsed');
        return alreadyshowing;
    },

    //
    // methods for expanding & collapsing sections
    //
    expandSection: function (sectionid) {
        const heading = this.getHeadingBySectionId(sectionid);
        const targetdiv = this.getTargetBySectionId(sectionid);
        const arrow = this.getHeadingArrowBySectionId(sectionid);

        targetdiv.classList.remove('collapsed');
        heading.setAttribute('aria-expanded', 'true');
        arrow.setAttribute('src', this.options.expandedArrowIcon);
    },
    collapseSection: function (sectionid) {
        const heading = this.getHeadingBySectionId(sectionid);
        const targetdiv = this.getTargetBySectionId(sectionid);
        const arrow = this.getHeadingArrowBySectionId(sectionid);

        targetdiv.classList.add('collapsed');
        heading.setAttribute('aria-expanded', 'false');
        arrow.setAttribute('src', this.options.collapsedArrowIcon);
    },
    sectionExpanded: function (sectionid) {
        const heading = this.getHeadingBySectionId(sectionid);
        const alreadyshowing = heading.getAttribute('aria-expanded') == 'true';
        return alreadyshowing;
    },
    toggleSection: function (sectionid) {
        const alreadyshowing = this.sectionExpanded(sectionid);
        if (alreadyshowing) this.collapseSection(sectionid);
        else this.expandSection(sectionid);
    },

    //
    // utility methods for fetching sections: heading/title bar, content section, etc.
    // and same for individual layers: their checkbox, their legend swatch, ...
    // used internally in other functions
    //
    getHeadingBySectionId: function (sectionid) {
        const heading = this._container.querySelector(`button.leaflet-biglayerpanel-accordion-title[aria-controls="${sectionid}"]`);
        return heading;
    },
    getHeadingArrowBySectionId: function (sectionid) {
        const heading = this.getHeadingBySectionId(sectionid);
        const arrow = heading.querySelector('img');
        return arrow;
    },
    getTargetBySectionId: function (sectionid) {
        const targetdiv = this._container.querySelector(`div.leaflet-biglayerpanel-accordion-section[id="${sectionid}"]`);
        return targetdiv;
    },

    getLayerPanel: function (layerid) {
        const targetdiv = this._container.querySelector(`div.leaflet-biglayerpanel-layer[data-layer-id="${layerid}"]`);
        return targetdiv;
    },
    getLayerTitle: function (layerid) {
        const panel = this.getLayerPanel(layerid);
        if (! panel) return undefined;
        const title = panel.querySelector('label').textContent.trim();
        return title;
    },
    getLayerCheckbox: function (layerid) {
        const panel = this.getLayerPanel(layerid);
        if (!panel) return null; // invalid layer ID, or maybe for a labels layer
        const checkbox = panel.querySelectorAll('input[type="checkbox"]')[0];  // always the 1st checkbox
        return checkbox;
    },
    getLayerLabelsCheckbox: function (layerid) {
        const panel = this.getLayerPanel(layerid);
        const checkbox = panel.querySelectorAll('input[type="checkbox"]')[1];  // always the 2nd checkbox... but may be undefined for layers without labels checkbox
        return checkbox;
    },
    getLayerSwatch: function (layerid) {
        const panel = this.getLayerPanel(layerid);
        if (! panel) return undefined;
        const swatch = panel.querySelector('div.leaflet-biglayerpanel-layer-swatch');
        return swatch;
    },
    getLayerLegendInfo: function (layerid) {
        const swatch = this.getLayerSwatch(layerid);
        if (! swatch) return undefined;

        let swatchtype = swatch.classList[1].split('-');
        swatchtype = swatchtype[swatchtype.length - 1];

        const color = swatch.style.backgroundColor;
        const image = swatch.style.backgroundImage;

        return {
            type: swatchtype,
            color: color,
            image: image,
        };
    },
    getLayerZIndex: function (layerid) {
        const panel = this.getLayerPanel(layerid);

        // some hardcoded values for layers that don't have a panel, e.g. collected calculablepolygon
        if (layerid == 'calcpoly-v2-boundary') return 100;
        if (layerid == 'calcpoly-v2-labels') return 900;
        if (layerid == 'venue-v2-publicschool-all') return 320;
        if (layerid == 'venue-v2-privateschool-all') return 320;

        // invalid layer ID, or maybe for a labels layer = default to fall to the bottom
        if (!panel) return null;

        const zindex = panel.getAttribute('data-layer-stacking');  // explicit data attribute in the HTML... or may be missing/undefined which is also OK
        return zindex ? parseInt(zindex) : null;
    },

    //
    // methods for toggling map layers when the checkboxes change, and for retrieving the current state
    // and fetching what layers are currently on (for the contrived legend control)
    //
    toggleLayer: function (maplayerid, showit) {
        // toggle the checkbox into the requested state, if it isn't already
        const checkbox = this.getLayerCheckbox(maplayerid);
        if (checkbox) checkbox.checked = showit;

        // toggle the layer in the combined-layer WMS source
        if (showit) this._wmssource.addSubLayer(maplayerid);
        else this._wmssource.removeSubLayer(maplayerid);

        // convenience hack
        // if they're turning retailersnearschools on/off, turn on/off schools to match
        // but only if they're not in Venue mode, in which case they certainly want to keep schools on by default
        if (maplayerid == 'retailersnearschools') {
            if (showit) {
                if (! this.isLayerOnMap('venue-v2-publicschool')) this.toggleLayer('venue-v2-publicschool', true);
                if (! this.isLayerOnMap('venue-v2-privateschool')) this.toggleLayer('venue-v2-privateschool', true);
            }
            else if (this.options.VENUESETID === 0) {
                if (this.isLayerOnMap('venue-v2-publicschool')) this.toggleLayer('venue-v2-publicschool', false);
                if (this.isLayerOnMap('venue-v2-privateschool')) this.toggleLayer('venue-v2-privateschool', false);
            }
        }

        // convenience hack
        // if they turn on one of the 3 retailer views, turn off the other retailer views, so it acts like a radio but is a checkbox
        const retailerlayers = ['retailerpoints', 'retailersnearretailers', 'retailersnearschools'];
        if (showit && retailerlayers.indexOf(maplayerid) !== -1) {
            retailerlayers.filter((id) => id !== maplayerid).forEach((id) => this.toggleLayer(id, false));
        }
    },
    listAllLayerIds: function () {
        const ids = [];
        Array.prototype.forEach.call(this._layercheckboxes, function (node) {
            const layerid = node.getAttribute('value');
            ids.push(layerid);
        });
        return ids;
    },
    listActiveLayerIds: function () {
        return this.listAllLayerIds().filter(maplayerid => this.isLayerOnMap(maplayerid));
    },
    isLayerOnMap: function (maplayerid) {
        // non-API hack: peek at the WMS source and see if this layer is listed
        return this._wmssource._subLayers[maplayerid] ? true : false;
    },
    activeLayerLegends: function () {
        const legendinfos = this.listActiveLayerIds()
        .map((layerid) => {
            return {
                title: this.getLayerTitle(layerid),
                swatch: this.getLayerLegendInfo(layerid),
            };
        })
        .filter((layerinfo) => {
            return layerinfo.title && layerinfo.swatch;  // if either is missing, bail
        });
        return legendinfos;
    },

    //
    // function for handling WMS GetFeatureInfo results
    //
    handleWmsGetFeatureInfoResults: function (latlng, xmlstring) {
        // the caller in map.js overrides this, as the legend control is getting a bit bulky already and map.js is where we would look for popups etc.
        console.debug([ 'handleWmsGetFeatureInfoResults', latlng, xmlstring ]);
    },

    //
    // functions for setting filters and resultset IDs, particularly for retailer searches
    // this means a lot of interplay between the retailers_map endpoint and the RetailerSearchResults it creates,
    // this control to pass the ID to MapServer, and the mapfile filtering by RETAILERSETID parameter
    //
    setRetailerFilterSetId: function (filterid) {
        this.options.RETAILERSETID = filterid;
        this._wmssource.refreshOverlay();
    },
    setVenueFilterSetId: function (filterid) {
        this.options.VENUESETID = filterid;
        this._wmssource.refreshOverlay();
    },

    //
    // functions relevant to picking one of the boundary layers
    //

    getCalculablePolygonBoundaryOverlaySelections: function () {
        const checkedboxes = Array.prototype.slice.call(this._calcpolyboundspickers).filter((checkbox) => { return checkbox.checked; });
        const layerids = checkedboxes.map((checkbox) => { return checkbox.value; });
        return layerids;
    },
    getCalculablePolygonLabelOverlaySelections: function () {
        const checkedboxes = Array.prototype.slice.call(this._calcpolylabelspickers).filter((checkbox) => { return checkbox.checked; });
        const layerids = checkedboxes.map((checkbox) => { return checkbox.value; });
        return layerids;
    },
    updateCalculablePolygonBoundaryOverlay: function () {
        // no preprocessing here; refreshOverlay() will check getCalculablePolygonBoundaryOverlaySelections()
        this._wmssource.refreshOverlay();
    },
    updateCalculablePolygonLabelOverlay: function () {
        // no preprocessing here; refreshOverlay() will check getCalculablePolygonLabelOverlaySelections()
        this._wmssource.refreshOverlay();
    },

    //
    // functions relevant to the demographic overlay
    //
    getDemographicSelection: function () {
        const polytype = this._demogpolytypepicker.value;
        const demogcheckbox = Array.prototype.slice.call(this._demogradios).filter((radiobox) => { return radiobox.checked; })[0];
        const demoglayer = demogcheckbox ? demogcheckbox.value : null;

        const info = {
            polytype: polytype,
            demoglayer: demoglayer,
        };
        return polytype && demoglayer ? info : null;
    },
    getCurrentDemographicLegendInfo: function () {
        const demogchoice = this.getDemographicSelection();
        return demogchoice ? this.getDemographicLegendInfo(demogchoice.demoglayer, demogchoice.polytype) : null;
    },
    getDemographicLegendInfo: function (whichdemog, polytype) {
        const section = this._container.querySelector(`div.leaflet-biglayerpanel-demographics-layer[data-demographics-polytype="${whichdemog}"]`);
        const title = section.querySelector('label').textContent.trim();
        const polytypetext = this._demogpolytypepicker.querySelector(`option[value="${polytype}"]`).textContent.trim();

        const legenddiv = section.querySelector('div.leaflet-biglayerpanel-demographics-layer-legend');
        const colors = [
            window.getComputedStyle(legenddiv.querySelector('span.leaflet-biglayerpanel-demographics-swatch.q1'), null).getPropertyValue("background-color"),
            window.getComputedStyle(legenddiv.querySelector('span.leaflet-biglayerpanel-demographics-swatch.q2'), null).getPropertyValue("background-color"),
            window.getComputedStyle(legenddiv.querySelector('span.leaflet-biglayerpanel-demographics-swatch.q3'), null).getPropertyValue("background-color"),
            window.getComputedStyle(legenddiv.querySelector('span.leaflet-biglayerpanel-demographics-swatch.q4'), null).getPropertyValue("background-color"),
            window.getComputedStyle(legenddiv.querySelector('span.leaflet-biglayerpanel-demographics-swatch.q5'), null).getPropertyValue("background-color"),
        ];

        const labels = legenddiv.querySelectorAll('label');
        const minlabel = labels[0].textContent.trim();
        const maxlabel = labels[labels.length - 1].textContent.trim();

        return {
            title: title,
            areaname: polytypetext,
            colors: colors,
            minlabel: minlabel,
            maxlabel: maxlabel,
        };
    },
    updateDemographicOverlay: function () {
        const demogchoice = this.getDemographicSelection();

        // show/hide the legends, showing whichever is selected... unless polytype=none in which case hide all of them
        this._demoglayers.forEach((layerdiv) => {
            const showit = demogchoice && layerdiv.getAttribute('data-demographics-polytype') == demogchoice.demoglayer;
            if (showit) layerdiv.classList.remove('collapsed');
            else layerdiv.classList.add('collapsed');
        });

        // remove demog overlay, then call refresh which will check getDemographicSelection() to add the new one and its params
        Object.keys(this._wmssource._subLayers).forEach((maplayerid) => {
            if (maplayerid.indexOf('demographic-') !== 0) return;
            this._wmssource.removeSubLayer(maplayerid, 'noredraw');
        });

        this._wmssource.refreshOverlay();
    },
    getDemogFormatString: function (demogtype, value) {
        let text = value;
        switch (demogtype) {
            case 'mhhinc':
                text = `$${parseInt(value).toLocaleString()}`;
                break;
            case 'pctblack':
            case 'pctamerind':
            case 'pctashawpi':
            case 'pctwhite':
            case 'pctotherrace':
            case 'pcthisp':
            case 'pctyouth':
                text = `${parseFloat(value).toFixed(1)}%`;
                break;
        }
        return text;
    },
});
