import styled from 'styled-components';
import { DEVICES } from '_constants';
import { UiColors } from 'assets/styles/old/global';

const Wrapper = styled.div.attrs(() => ({ ariaRequired: true }))`
  display: flex;
  flex-wrap: nowrap;
  border: 1px solid var(--grey);
  border-bottom: none;
  width: 100%;
  .BaseSelect {
    &__control {
      width: 200px;
    }
  }

  button:not(:first-of-type) {
    margin-left: 10px;
  }
  @supports (border: none) {
    border: none;
  }
`;

const ButtonTab = styled.button.attrs(() => ({ role: 'button', ariaRequired: true, ariaPressed: 'false' }))`
  height: 44px;
  flex-direction: row;
  flex-shrink: 1;
  text-align: center;
  width: 193px;
  font-size: 16px;
  font-family: Roboto;
  background: var(--white);
  border: 1px var(--grey) solid;
  border-bottom: ${({ isClicked }) => (isClicked ? `4px solid ${UiColors.forestColor}` : `none`)};
  box-sizing: border-box;

  @media ${DEVICES.laptop} {
    font-size: 14px;
  }

  @media ${DEVICES.tablet} {
    display: none;
  }

  &:hover {
    background: var(--soft-grey);
  }
  &:focus {
    outline-color: var(--dodger-blue) !important;
  }
`;

const DropdownWrapper = styled.div`
  width: 100%;
  margin-bottom: 15px;
`;

export { ButtonTab, Wrapper, DropdownWrapper };
