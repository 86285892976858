import styled from 'styled-components';
import { DEVICES } from '_constants';

export const Wrapper = styled.div`
  width: auto;
`;

export const Title = styled.h2`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
`;

export const List = styled.ul``;

export const Item = styled.li`
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  height: 39px;

  :not(:last-of-type) {
    margin-bottom: 10px;
  }

  @media ${DEVICES.tablet} {
    flex-flow: row wrap;
    height: auto;

    :not(:last-of-type) {
      margin-bottom: 20px;
    }
  }

  > button {
    height: 52px;
    padding: 0 15px;

    @media ${DEVICES.tablet} {
      width: 50%;
    }

    :first-of-type {
      border-right: 1px solid var(--white);
    }
  }
`;

export const TextWrapper = styled.div`
  border: 1px solid var(--grey);
  background: var(--white);
  border-radius: 3px 0px 0px 3px;
  max-width: 445px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 15px;

  @media ${DEVICES.tablet} {
    max-width: 100%;
    margin-bottom: 10px;
  }
`;

export const Text = styled.p`
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${DEVICES.tablet} {
    max-width: 100%;
    white-space: normal;
    overflow: visible;
  }
`;

export const Info = styled.p`
  font-size: 16px;
`;
