import styled from 'styled-components';
import { Button, Input } from 'ui-kit';
import { DEVICES } from '_constants';

export const Wrapper = styled.div`
  height: 100%;
  padding: 40px 16px 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${DEVICES.tablet} {
    position: relative;
    flex-direction: column;
  }
`;

export const FormWrapper = styled.form`
  background: var(--white);
  width: 40%;
  max-width: 420px;
  padding: 35px 35px 25px;
  border: 1px solid var(--grey);
  border-radius: 12px;
  margin-right: 45px;

  @media ${DEVICES.tableView} {
    margin-right: 20px;
  }

  @media ${DEVICES.tablet} {
    width: 100%;
    padding: 0;
    margin: 0 0 40px;
    border: none;
    padding: 20px;
  }
`;

export const Title = styled.h1`
  text-align: center;
  color: var(--dark);
  font-size: 26px;
  line-height: 1.1;
  margin-bottom: 50px;
  font-weight: 500;
`;

export const LoginInput = styled(Input)`
  margin: 0 0 57px;

  @media ${DEVICES.tablet} {
    margin: 0 0 50px;
  }
`;

export const Requirements = styled.div`
  width: 40%;
  height: auto;

  @media ${DEVICES.tablet} {
    max-width: 420px;
    width: 100%;
    margin-bottom: 70px;
  }
`;

export const SubmitButton = styled(Button).attrs(() => ({
  type: 'submit',
}))`
  min-width: 147px;
  margin: 0 auto;
  border-radius: 3px;
  font-weight: 500;
  width: auto;
  max-width: 100%;

  @media ${DEVICES.tablet} {
    width: 100%;
    max-width: 420px;
  }
`;

export const SubmitButtonWrapper = styled.div`
  @media ${DEVICES.tablet} {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 12px 16px;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.09);
  }
`;
