import { EXPIRED_LINK } from '_constants';
import { getProfilePermissions, getDashboardInfoSections } from 'utils/custom';
import * as TYPES from './Auth.types';

const initialState = {
  role: '',
  email: '',
  firstName: '',
  lastName: '',
  isAuthenticated: false,
  isValidLinkMessage: false,
  appCanStart: false,
  permissions: [],
  permissionStatus: false,
  agencies: [],
  stateAddress: '',
  subdomain: '',
  dashboardLinks: [],
  dashboardLinksAccount: [],
  timeZone: '',
  accountProduct: '',
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPES.SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };

    case TYPES.START_WITHOUT_TOKEN:
      return {
        ...state,
        appCanStart: true,
      };

    case TYPES.LOG_OUT:
      return {
        ...state,
        token: '',
        isAuthenticated: false,
        appCanStart: true,
        usersImport: {},
        retailersImport: {},
      };

    case TYPES.SIGN_IN_SUCCESS: {
      const {
        email,
        first_name: firstName,
        last_name: lastName,
        permissions: corePermissions,
        modularity_permissions: modularityPermissions,
        state: stateAddress,
        id,
        subdomain,
        dashboard_user_guide_link: userGuideLink,
      } = action.result;
      const permissions = corePermissions.concat(modularityPermissions);
      return {
        ...state,
        permissions,
        firstName,
        lastName,
        email,
        isAuthenticated: true,
        appCanStart: true,
        stateAddress,
        userId: id,
        userGuideLink,
        subdomain,
      };
    }

    case TYPES.GET_PROFILE_DATA_SUCCESS: {
      const {
        email,
        first_name: firstName,
        last_name: lastName,
        state: stateAddress,
        subdomain,
        dashboard_user_guide_link: userGuideLink,
        campaign_ids: campaignIds,
        modularity_permissions: modularityPermissions,
        dashboard_links: dashboardLinks,
        dashboard_links_of_account: dashboardLinksAccount,
        tz: timeZone,
        account_product: accountProduct,
      } = action?.result?.data[0]?.attributes;
      const userId = action?.result?.data[0]?.id;
      const retailersImport = action?.result?.data[0]?.relationships?.retailers_imports;
      const usersImport = action?.result?.data[0]?.relationships?.users_imports;
      const { permissions } = getProfilePermissions({
        data: action.result.data,
        included: action.result.included,
        modularityPermissions,
      });
      // FIXME: refactor: replace this field in project with 'secGeogList'
      const agencies = action?.result?.included.filter((item) => item.type === 'AggregationPolygon');
      const relationAgencies = action?.result?.data[0]?.relationships?.agencies?.data.map((item) => item.id);
      const includedAgency = action?.result?.included.filter((item) => item.type === 'Agency');
      const agenciesList = relationAgencies.map((item) => {
        return includedAgency.find((elem) => elem.id === item);
      });
      const secGeogList = action?.result?.included.filter((item) => item.type === 'AggregationPolygon');
      const role = action?.result?.included.find((item) => item.type === 'Role');
      return {
        ...state,
        permissions,
        email,
        firstName,
        lastName,
        isAuthenticated: true,
        appCanStart: true,
        agencies,
        stateAddress,
        userId,
        userGuideLink,
        agenciesList,
        secGeogList,
        role,
        campaignIds,
        subdomain,
        modularityPermissions,
        dashboardLinks: getDashboardInfoSections(dashboardLinks),
        dashboardLinksAccount,
        timeZone,
        accountProduct,
        retailersImport,
        usersImport,
      };
    }
    case TYPES.GET_MODULARITY_PERMISSION_SUCCESS: {
      const permissions = action.result.data;
      return {
        ...state,
        permissionStatus: true,
        modularityPermissions: permissions,
      };
    }
    case TYPES.SIGN_IN_FAIL:
      return {
        ...state,
        isAuthenticated: false,
      };

    case TYPES.IS_VALID_LINK_FAIL: {
      return {
        ...state,
        isValidLinkMessage: EXPIRED_LINK === action?.error?.response?.data?.detail,
      };
    }

    case TYPES.SET_NEW_PASSWORD_FAIL: {
      return {
        ...state,
        isAuthenticated: false,
      };
    }

    default:
      return state;
  }
};

export default reducer;
