/* Libs */
import { UiColors } from 'assets/styles/old/global';
import styled, { css } from 'styled-components';
import { deviceLess } from '_constants/DEVICES';

export const Wrapper = styled.div`
  position: relative;
  [type='radio']:checked,
  [type='radio']:not(:checked) {
    position: absolute;
    left: 0;
    opacity: 0;
  }
  [type='radio']:checked + label,
  [type='radio']:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    display: inline-block;
    color: ${UiColors.forestColor};
    font-size: 14px;
  }
  [type='radio']:checked + label:before,
  [type='radio']:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background: var(--white);
  }

  [type='radio']:not(:checked) + label:before {
    border: 1px solid ${UiColors.forestColor};
  }

  [type='radio']:checked + label:before {
    border: 1px solid ${UiColors.forestColor};
  }

  [type='radio']:checked + label:after,
  [type='radio']:not(:checked) + label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: ${UiColors.forestColor};
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type='radio']:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type='radio']:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`;

export const Label = styled.label`
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`;

export const Input = styled.input`
  background-color: black;
  & + label {
    border: 2px solid transparent;
  }

  &:focus {
    & + label {
      border-color: var(--focus);
      outline: 1px dotted;
      outline: auto -webkit-focus-ring-color;
    }
  }
`;

export const OptionsWrapper = styled.div`
  display: grid;
  column-gap: ${({ gap }) => gap || '24px'};
  row-gap: ${({ gap }) => gap || '16px'};
  grid-auto-flow: ${({ direction }) => direction || 'column'};
  grid-template-columns: ${({ direction }) =>
    direction === 'column' ? 'repeat(auto-fit, minmax(50px, 1fr))' : 'unset'};
`;

export const Error = styled.span`
  display: block;
  margin-top: 8px;
  font-size: 11px;
  color: ${UiColors.orangeColor};
`;

export const Title = styled.span`
  display: block;
  margin-bottom: 12px;
`;

const columnsStyles = {
  three_columns: css`
    @media ${deviceLess.laptop} {
      display: flex;
      flex: 2;
      padding-bottom: 20px;

      ${Title} {
        flex: 1;
      }

      ${OptionsWrapper} {
        flex: 1;
      }
    }
  `,
  two_columns: css`
    @media ${deviceLess.laptop} {
      display: flex;

      ${Title} {
        min-width: 400px;
      }

      ${OptionsWrapper} {
        width: 100%;
        max-width: 1200px;
      }
    }
  `,
  youth_columns: css`
    @media ${deviceLess.laptop} {
      display: flex;

      ${Title} {
        min-width: 400px;
      }

      ${OptionsWrapper} {
        width: 100%;
        max-width: 1200px;
      }
    }
  `,
  one_column_with_submission: css`
    @media ${deviceLess.laptop} {
      display: flex;

      ${Title} {
        width: 100%;
        min-width: 200px;
        max-width: 50%;
        display: inline-block;
        word-wrap: break-word;
      }

      ${OptionsWrapper} {
        padding-left: 60px;
        width: 100%;
        max-width: 600px;
        justify-content: space-between;
        display: flex;
        flex-direction: row;
      }
    }
  `,
  '': ``,
};

export const RadioGroupWrapper = styled.div`
  position: relative;
  ${({ pageColumns }) => pageColumns && columnsStyles[pageColumns]}
`;

export const HorizontalBorder = styled.div`
  @media ${deviceLess.laptop} {
    border-bottom: 1px solid #e0e0e0;
    position: absolute;
    height: 100%;
    left: -20px;
    width: calc(100% + 40px);
    z-index: 1;
    filter: opacity(0.4);
  }
`;
