import styled from 'styled-components';
import { DEVICES } from '_constants';

export const Wrapper = styled.div`
  padding: 40px 16px 50px;
`;

export const PageTitle = styled.h1`
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 22px;
`;

export const Box = styled.article`
  padding: 16px;
  border: 4px solid #f0eae8;
  background: var(--white);
`;

export const TopLine = styled.section`
  display: flex;
  align-items: stretch;
  flex-flow: row wrap;

  @media ${DEVICES.tablet} {
    display: block;
  }

  ${Box} {
    flex: 1 0 calc((100% - 60px) / 4);
    margin-bottom: 20px;
    max-width: calc((100% - 60px) / 4);

    :not(:nth-of-type(4n)) {
      margin-right: 20px;

      @media ${DEVICES.tablet} {
        margin-right: 0;
      }
    }

    @media ${DEVICES.tablet} {
      max-width: 100%;
    }

    > a {
      color: #5968b2;
      text-decoration: underline;
    }
  }
`;

export const Title = styled.h1`
  font-size: 19px;
  line-height: 1.1;
  font-weight: 700;
  margin-bottom: 12px;
`;

export const Description = styled.p`
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 16px;
  font-weight: 500;
`;

export const BottomLine = styled.section`
  display: flex;
  flex-flow: row wrap;

  ${Box} {
    flex: 1 0 50%;
    max-width: calc(50% - 10px);
    margin-bottom: 20px;

    :nth-child(odd) {
      margin-right: 20px;
    }
    @media ${DEVICES.mobileXL} {
      min-width: 100%;
      margin-right: 0;
    }
  }

  ${Title} {
    margin-bottom: 24px;
  }
`;

export const ButtonRow = styled.nav`
  display: flex;
  flex-flow: row nowrap;

  > button {
    padding: 0 12px;

    :not(:last-of-type) {
      margin-right: 20px;
    }
  }

  @media ${DEVICES.laptop} {
    flex-flow: column nowrap;

    > button {
      padding: 12px 0;

      :not(:last-of-type) {
        margin: 0 0 20px 0;
      }
    }
  }
`;

export const IconWrapper = styled.div`
  width: 66px;
  height: 66px;
  border-radius: 50%;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: start;

  @media ${DEVICES.tablet} {
    width: 52px;
    height: 52px;
  }
`;

export const Icon = styled.img`
  width: 38px;
  height: 38px;

  @media ${DEVICES.tablet} {
    width: 28px;
    height: 28px;
  }
`;

export const A = styled.a`
  color: #5968b2;
`;
