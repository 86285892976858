import { UiColors } from 'assets/styles/old/global';
import styled from 'styled-components';

export const ProgressBarWrapper = styled.div`
  position: static;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: fit-content;
  padding: 10px 25px;
  border-bottom: 1px solid var(--rgb-grey);
`;

export const FileName = styled.span`
  font-size: 15px;
  line-height: 1.1;
  font-weight: 500;
  margin: auto;
  margin-bottom: 12px;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  margin: 0 8px;
  align-items: center;
  width: 100%;
  min-height: 20px;
  background-color: var(--soft-dark);
  border-radius: 8px;
  overflow: hidden;
`;

export const Filler = styled.div`
  height: 20px;
  width: ${(props) => props.progress}%;
  background-color: ${UiColors.orangeColor};
  border-right-radius: 8px;
  transition: width 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Label = styled.span`
  position: absolute;
  left: 50%;
  margin: auto;
  color: var(--white);
  font-weight: bold;
`;

export const CancelIconWrapper = styled.button`
  z-index: 2;
  margin-left: auto;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
`;

export const BarText = styled.p`
  span {
    position: relative;
    right: calc(15%);
    padding-right: 15px;
  }
`;
