import React, { useCallback, useReducer } from 'react';
import { AuthEntity } from '_entities';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { PageContainer } from 'modules';
import { BUTTON_TYPES, INPUT_TYPE, URLS, MESSAGES } from '_constants';
import { notification } from 'utils/services';
import { formInitialState, FORM_ACTION_TYPES, formNames } from './constants';
import { formReducer, init } from './_utils';
import List from './components/List';
import * as Styled from './styles';

const { resetPassword } = AuthEntity.actions;

function SetNewPassword() {
  const [formState, formDispatch] = useReducer(formReducer, formInitialState, init);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleChange = useCallback(({ target: { name, value } }) => {
    formDispatch({ type: FORM_ACTION_TYPES.ENTER_DATA, name, payload: value });
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      formDispatch({ type: FORM_ACTION_TYPES.IS_EQUAL });
      const { password, confirmPassword } = formState.data;
      if (password !== confirmPassword) return;
      try {
        const resetCode = sessionStorage.getItem('code');
        const data = {
          password: formState.data.password,
          code: resetCode,
        };
        await dispatch(resetPassword(data));
        notification.success(MESSAGES.RESET.SUCCESS);
        history.push(URLS.login);
      } catch (err) {
        console.log(err);
        const responseData = err.response.data;
        if (responseData?.password?.length) {
          formDispatch({ type: FORM_ACTION_TYPES.RESET_INVALID, payload: responseData?.password });
        }
        if (responseData?.detail) {
          notification.error(responseData.detail);
        }
      }
    },
    [dispatch, formState, history],
  );

  return (
    <PageContainer preventNotificationDismiss>
      <Styled.Wrapper>
        <Styled.FormWrapper>
          <Styled.Title>Set new password</Styled.Title>
          <Styled.LoginInput
            name={formNames.password}
            value={formState.data.password}
            error={formState.errors.password}
            type={INPUT_TYPE.PASSWORD}
            floatingLabel
            title="New Password"
            onChange={handleChange}
          />
          <Styled.LoginInput
            name={formNames.confirmPassword}
            value={formState.data.confirmPassword}
            error={formState.errors.confirmPassword}
            type={INPUT_TYPE.PASSWORD}
            floatingLabel
            title="Confirm password"
            onChange={handleChange}
          />
          <Styled.SubmitButtonWrapper>
            <Styled.SubmitButton onClick={handleSubmit} text="Confirm & Proceed" variant={BUTTON_TYPES.DARK} />
          </Styled.SubmitButtonWrapper>
        </Styled.FormWrapper>
        <Styled.Requirements>
          {formState.passwordRules.map((item) => (
            <List {...item} key={item.title} />
          ))}
        </Styled.Requirements>
      </Styled.Wrapper>
    </PageContainer>
  );
}

export default SetNewPassword;
