import styled from 'styled-components';
import { DEVICES } from '_constants';

export const Wrapper = styled.div`
  height: 100%;
  padding: 40px 16px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextContainer = styled.div`
  disply: block;
`;

export const Title = styled.h1`
  text-align: center;
  color: var(--dark);
  font-size: 28px;
  line-height: 1.14;
  margin-bottom: 25px;
  font-weight: 500;

  @media ${DEVICES.tablet} {
    font-size: 20px;
    line-height: 1.4;
    margin-bottom: 20px;
  }
`;

export const Description = styled.p`
  max-width: 550px;
  font-size: 20px;
  line-height: 1.6;
  text-align: center;
  color: var(--dark);

  @media ${DEVICES.tablet} {
    font-size: 16px;
    line-height: 1.5;
  }

  > a {
    color: #5968b2;
    font-size: inherit;
    line-height: inherit;
    text-decoration: underline;
    margin-left: 3px;

    :hover {
      text-decoration: none;
    }
  }
`;
