/* eslint-disable */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, Loader } from 'ui-kit';
import { toast } from 'react-toastify';
import { notification } from 'utils/services';
import { BUTTON_TYPES } from '_constants';
import { useDispatch, useSelector } from 'react-redux';
import { RetailerEntity, UserEntity, AuthEntity } from '_entities';
import apiData from '_entities/old/api/config';
import * as Styled from '../styles';
import * as ModalStyles from './styles';

const { postRetailersImport, getRetailersImportProgress, resetRetailerImportData } = RetailerEntity.actions;
const { postUsersImport, getUsersImportProgress, resetUserImportInfo } = UserEntity.actions;
const { getLastRetailerImport, getRetailerToastId } = RetailerEntity.selectors;
const { getLastUserImport, getUserToastId } = UserEntity.selectors;
const { getProfileData } = AuthEntity.actions;

const getFileDisplayName = ({ file, isDropDown }) => {
  if (!file && !isDropDown) return 'No file chosen';
  if (!file && isDropDown) return 'Drag & Drop your CSV file here or click to select';
  const { name } = file;
  return `File selected: ${name.length > 130 ? `${name.slice(0, 129)}...` : name}`;
};

const PROGRESS_STATES = ['VALIDATION_FAILED', 'SUCCESS', 'FAILED'];

const MODAL_ACTIONS = {
  templates: {
    retailers: 'static/Template_Batch Retailer Upload_Use For POST Survey.csv',
    users: 'static/Template_Batch User Upload_Use For POST Survey.csv',
  },
  instructions: {
    retailers: 'static/Instructions_Batch Retailer Upload_For POST Survey.csv',
    users: 'static/Instructions_Batch User Upload_For POST Survey.csv',
  },
  import: {
    retailers: postRetailersImport,
    users: postUsersImport,
  },
  importProgress: {
    retailers: getRetailersImportProgress,
    users: getUsersImportProgress,
  },
  resetImportInfo: {
    retailers: resetRetailerImportData,
    users: resetUserImportInfo,
  },
  payloadType: {
    retailers: 'RetailersImportProcess',
    users: 'UsersImportProcess',
  },
  instructionsList: {
    retailers: [
      <p>Only use the upload template provided.</p>,
      <p>Review the template instructions for filling in the template.</p>,
      <p>Only one file may be uploaded at a time.</p>,
      <p>The file type should be .csv.</p>,
      <p>Before uploading, please check that everything is correctly filled out.</p>,
      <p>The progress bar shows the status of your upload.</p>,
      <p>Retailers are successfully uploaded upon seeing a confirmation message.</p>,
      <p>If the upload fails, you will receive an email from the site about what to fix.</p>,
      <p>
        This email comes from
        <span> noreply@countertools.org.</span> Check Spam if you don't see it initially.
      </p>,
      <p>Once the file starts uploading, the process can not be interrupted until it is finished.</p>,
    ],
    users: [
      <p>Only use the upload template provided.</p>,
      <p>Review the template instructions for filling in the template.</p>,
      <p>Only one file may be uploaded at a time.</p>,
      <p>The file type should be .csv.</p>,
      <p>Before uploading, please check that everything is correctly filled out.</p>,
      <p>The progress bar shows the status of your upload.</p>,
      <p>Users are successfully uploaded upon seeing a confirmation message.</p>,
      <p>
        Once uploaded, users will automatically receive an email from the site in order to make a password and log in.
      </p>,
      <p>If the upload fails, you will receive an email from the site about what to fix.</p>,
      <p>
        Any emails from the site come from <span> noreply@countertools.org. </span>
        Check Spam if you don't see an email initially.
      </p>,
      <p>Once the file starts uploading, the process can not be interrupted until it is finished.</p>,
    ],
  },
};

const { baseUrl } = apiData;

function BatchUploadModal({ isOpen, onClose, modalType }) {
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [base64, setBase64] = useState('');
  const lastRetailerImport = useSelector(getLastRetailerImport);
  const lastUserImport = useSelector(getLastUserImport);
  const retailerToastId = useSelector(getRetailerToastId);
  const userToastId = useSelector(getUserToastId);
  const dispatch = useDispatch();
  const [isTemplateButtonDisabled, setIsTemplateButtonDisabled] = useState(false);
  const [isInstructionsButtonDisabled, setIsInstructionsButtonDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const LAST_IMPORT = {
    lastImport: { users: lastUserImport, retailers: lastRetailerImport },
    resetImportInfo: { users: resetUserImportInfo, retailers: resetRetailerImportData },
  };

  const handleDownloadTemplateBtn = () => {
    setIsLoading(true);
    setIsTemplateButtonDisabled(true);
    try {
      window.location.href = `${baseUrl}${MODAL_ACTIONS.templates[modalType]}`;
    } catch (e) {
      console.log(e);
    } finally {
      setIsTemplateButtonDisabled(false);
      setIsLoading(false);
    }
  };

  const handleDownloadInstructionsBtn = async () => {
    setIsLoading(true);
    setIsInstructionsButtonDisabled(true);
    try {
      window.location.href = `${baseUrl}${MODAL_ACTIONS.instructions[modalType]}`;
    } catch (e) {
      console.log(e);
    } finally {
      setIsInstructionsButtonDisabled(false);
      setIsLoading(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile && droppedFile.type === 'text/csv') {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBase64(reader.result);
      };
      reader.readAsDataURL(droppedFile);
      setFile(droppedFile);
    } else {
      notification.error('Please drop a CSV file.');
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileInput = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === 'text/csv') {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBase64(reader.result);
      };
      reader.readAsDataURL(selectedFile);
      setFile(selectedFile);
    } else {
      notification.error('Please select a CSV file.');
    }
  };

  const openFileDialog = () => {
    fileInputRef.current.click();
  };

  const handleUploadFile = async () => {
    if (!file) return notification.error('You should select a CSV file first.');
    if (PROGRESS_STATES.includes(LAST_IMPORT.lastImport[modalType]?.attributes?.status)) {
      await dispatch(LAST_IMPORT.resetImportInfo[modalType]());
      toast.dismiss(modalType === 'users' ? userToastId : retailerToastId);
    }
    setIsLoading(true);
    try {
      const payload = {
        data: {
          type: MODAL_ACTIONS.payloadType[modalType],
          attributes: {
            import_file: {
              filename: file?.name,
              content: base64,
            },
          },
        },
      };

      await dispatch(MODAL_ACTIONS.import[modalType](payload));
      await dispatch(getProfileData());
    } catch (err) {
      const errorMessageArray = err?.response?.data?.errors;

      if (Array.isArray(errorMessageArray)) {
        const [errorMessage] = errorMessageArray;
        notification.error(errorMessage.detail);
      } else {
        notification.error('Something went wrong during the request');
      }
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  if (isLoading) return <Loader />;

  return (
    <Modal open={isOpen} onClose={isLoading ? () => {} : onClose}>
      <Styled.ModalWrapper>
        <Styled.ModalTitle>Batch upload {modalType}</Styled.ModalTitle>
        <ModalStyles.ModalDescription>
          {`For adding a list of ${modalType} to the site, use the provided template and instructions to batch upload these
          ${modalType}.`}
        </ModalStyles.ModalDescription>
        <ModalStyles.ButtonWrapper>
          <Styled.ModalButtonSet
            onClick={handleDownloadTemplateBtn}
            text="Download template"
            variant={BUTTON_TYPES.DANGER}
            aria-label="Download template"
            disabled={isTemplateButtonDisabled}
          />
          <Styled.ModalButtonSet
            onClick={handleDownloadInstructionsBtn}
            text="Download template instructions"
            variant={BUTTON_TYPES.DANGER}
            aria-label="Download template instructions"
            disabled={isInstructionsButtonDisabled}
          />
        </ModalStyles.ButtonWrapper>

        <ModalStyles.List>
          {MODAL_ACTIONS.instructionsList[modalType]?.map((el, index) => (
            <li key={index}>{el}</li>
          ))}
        </ModalStyles.List>

        <ModalStyles.DropZone onDrop={handleDrop} onDragOver={handleDragOver} onClick={openFileDialog}>
          <ModalStyles.ChosenFileText>{getFileDisplayName({ file, isDropDown: true })}</ModalStyles.ChosenFileText>
        </ModalStyles.DropZone>
        <ModalStyles.FileInput type="file" accept=".csv" ref={fileInputRef} onChange={handleFileInput} />
        <ModalStyles.ButtonWrapper>
          <ModalStyles.ChooseFileButton onClick={openFileDialog}>Choose File</ModalStyles.ChooseFileButton>
          <ModalStyles.ChosenFileText>{getFileDisplayName({ file })}</ModalStyles.ChosenFileText>
        </ModalStyles.ButtonWrapper>
        <Styled.ModalButtonSet
          onClick={handleUploadFile}
          text="Upload .csv file"
          variant={BUTTON_TYPES.DARK}
          aria-label="Upload .csv file"
        />
      </Styled.ModalWrapper>
    </Modal>
  );
}

BatchUploadModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  modalType: PropTypes.string,
};

BatchUploadModal.defaultProps = {
  modalType: 'retailers',
};

export default BatchUploadModal;
