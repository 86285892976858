import styled, { css } from 'styled-components';
import { DEVICES } from '_constants';
import { UiColors } from 'assets/styles/old/global';

export const RouteListItem = styled.li.attrs(() => ({
  ariaRequired: true,
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  color: ${UiColors.peachColor};
  line-height: 16px;
  height: 100%;

  @media (max-width: 1235px) {
    span {
      max-width: 150px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &:not(:first-child) {
    margin: 0 0 0 50px;

    @media ${DEVICES.laptop} {
      margin: 0 0 0 20px;
    }
  }

  &:last-child {
    margin: 0 0 0 auto;
    > div {
      text-transform: lowercase;
      font-size: 16px;
    }
  }
`;

export const RouteList = styled.ul.attrs(() => ({
  ariaRequired: true,
}))`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
`;

export const DropDownContentItem = styled.li`
  list-style: none;
  ${({ border }) =>
    border &&
    css`
      border-bottom: 1px solid var(--grey);
    `};
  ${({ borderAll }) =>
    borderAll &&
    css`
      border-bottom: 1px solid var(--grey);
      border-top: 1px solid var(--grey);
    `};
`;

export const DeviderText = styled.div`
  display: none;
`;

const BaseUL = styled.ul.attrs(() => ({
  ariaRequired: true,
}))`
  display: none;
  position: absolute;
  background-color: var(--soft-grey);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 0;
  margin: 0;
  top: ${({ type }) => (type === 'login' ? '54px !important;' : 'auto')};
  @media ${DEVICES.tablet} {
    box-shadow: none;
    position: relative;
  }
`;

export const DropDownContent = styled(BaseUL)`
  ${({ isDropDownOpen }) =>
    isDropDownOpen &&
    css`
      display: block;
      top: 54px;
      background: #f7f2f0;
      color: ${UiColors.forestColor};
    `};

  @media print {
    display: none !important;
  }

  a {
    text-decoration: none;
    cursor: pointer;
    text-align: left;
    padding: 12px 16px;
    display: block;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
    color: ${UiColors.forestColor};

    &:hover {
      background: var(--white--2);
    }
  }
`;

export const DropDownLink = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ isLogin }) =>
    isLogin &&
    css`
      height: 100%;
      margin-right: 5px;
    `};
  @media print {
    display: none !important;
  }

  &:focus {
    box-shadow: 0 0 0 2px var(--focus);
  }

  @supports (display: grid) {
    &:hover ${DropDownContent} {
      @media ${DEVICES.tablet} {
        flex-direction: column;
        transform: none;
        background: var(--grey);
      }
    }
    &:focus ${DropDownContent} {
      @media ${DEVICES.tablet} {
        flex-direction: column;
        transform: none;
        background: var(--grey);
      }
    }

    &:focus-within ${DropDownContent} {
      @media ${DEVICES.tablet} {
        flex-direction: column;
        transform: none;
        background: var(--grey);
      }
    }
  }
`;

export const DropButton = styled.div.attrs(() => ({
  ariaRequired: true,
}))`
  text-align: center;
  text-decoration: none;
  padding: 0 2px;
  @media print {
    display: none !important;
  }
  display: flex;
  align-items: center;
  &:hover {
    span {
      top: 3px;
    }
  }
`;

export const DropButtonText = styled.span.attrs(() => ({
  ariaRequired: true,
}))`
  display: inline-block;
  margin: 0 3px;
`;

export const ArrowMenu = styled.span`
  border: solid ${UiColors.peachColor};
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 2.4px;
  position: relative;
  top: 1px;
  transform: rotate(45deg);
  margin: 0 5px 4px;
  transition: 0.5s;

  ${({ isDropDownOpen }) =>
    isDropDownOpen &&
    css`
      transform: rotate(225deg);
      transition: 0.7s;
      transform: rotate(225deg);
      margin-top: 3px;
    `}
`;

export const CountItem = styled.span`
  display: inline-block;
  margin-left: 5px;
  background: ${UiColors.orangeColor};
  text-align: center;
  min-width: 14px;
  height: 14px;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  color: var(--white);
  border-radius: 7px;
  padding: 0 4px;
`;
