import { UiColors } from 'assets/styles/old/global';
import styled from 'styled-components';
import { Section } from 'ui-kit/Section/styles';
import { DEVICES } from '_constants';
import { deviceLess } from '_constants/DEVICES';
import { ButtonWrapper } from 'ui-kit/Button/styles';

export const Wrapper = styled.div`
  height: 100%;
  padding: 40px 25px 50px;
  @media ${deviceLess.desktopHd} {
    display: flex;
    flex-direction: column;
    max-width: 1920px;
    margin: auto;
  }

  > ${Section} {
    ${ButtonWrapper} {
      position: absolute;
      top: 25px;
      right: 35px;
      padding: 0 20px;
    }
  }
`;

export const Page = styled.span`
  color: var(--dim-grey);
`;

export const FormWrapper = styled.div`
  margin-top: 15px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;

  > button {
    width: 130px;
  }

  > button:first-child {
    margin-right: 20px;
  }
`;

export const ResultWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  > h2 {
    font-weight: 500;
    color: var(--dim-grey);
    margin-bottom: 20px;
  }

  > img {
    margin-bottom: 30px;
  }
`;

export const InputWrapper = styled.div`
  margin-bottom: 25px;

  input {
    max-width: 320px;
    width: 100%;
  }

  .react-time-picker {
    max-width: 320px;
  }
`;

export const SubmissionItemWrapper = styled.div`
  margin-bottom: 10px;
   > dt{
  margin: 0;
   }

  > dd {
  min-width: 298px;
  display: inline-block;
  word-wrap: break-word;
  }

@media ${deviceLess.laptop} {
  display: flex;
  flex-direction: row;
  
  dt {
    width: 130px;
    min-width: 130px;
  }
  > dd {
    padding-left: 10px;
    width: 100%;
    min-width: 200px;
    display: inline-block;
    word-wrap: break-word;
  }
}
}`;

export const RowWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;

  .sv_nav input {
    font-weight: normal !important;
    height: 40px !important;
    width: 130px !important;
    font-size: 14px !important;
  }

  .sv_q_text_root,
  .sv_q_dropdown_control {
    width: 320px !important;
  }

  .sv_body {
    border: none !important;
    padding: none !important;
    padding: 1em 0em 1.6428em 0em !important;
    color: ${UiColors.forestColor};
  }

  div[data-name='latlong'] {
    div {
      max-width: 200px;
    }
  }

  .GPS {
    background-color: ${UiColors.mangoColor} !important;
    color: ${UiColors.forestColor} !important;

    :hover {
      filter: saturate(2);
    }
  }

  .sv_q.sv_qstn {
    padding: 0.5em 0em 1.5em 0em !important;
  }
  .sv_row:nth-child(2n) {
    background-color: inherit !important;
  }
  .sv_page_title {
    display: none !important;
  }

  .sv_header {
    display: none !important;
  }

  input {
    appearance: auto !important;
  }

  .sv_q_radiogroup_control_item {
    -webkit-appearance: radio !important;
  }

  .sv_q_checkbox_control_item {
    -webkit-appearance: checkbox !important;
  }

  .sv_nav input {
    float: none !important;
  }

  .sv-action-bar {
    padding: 0 !important;

    .sv_complete_btn {
      margin: 0;
    }
  }

  .sv_q_checkbox_control_item,
  .sv_q_radiogroup_control_item {
    accent-color: ${UiColors.forestColor};
    filter: saturate(3);
  }

  .sv_next_btn,
  .sv_complete_btn {
    background-color: ${UiColors.mangoColor} !important;
    color: ${UiColors.forestColor} !important;
    :hover {
      filter: saturate(2);
    }
  }

  .sv_prev_btn {
    margin-right: 15px !important;
    background-color: ${UiColors.forestColor} !important;
    color: ${UiColors.peachColor} !important;
  }

  .sv_q_file_preview > img {
    width: 206px !important;
  }

  .sv_q_file_choose_button {
    background-color: var(--white-smoke) !important;
    color: var(--gray-20) !important;
  }

  .sv_q_file_remove_button {
    display: none !important;
  }

  .sv_q_dropdown__value {
    span {
      display: inline-block;
      width: 100%;
    }
  }

  .sv_q_file_remove {
    margin-top: 10px;
  }

  .sv_q_title > .sv_q_num,
  .sv_q_title > .sv-string-viewer {
    font-size: 16px !important;
    font-weight: 400 !important;
  }

  .react-time-picker
    .react-time-picker__wrapper
    .react-time-picker__inputGroup__input.react-time-picker__inputGroup__hour {
    width: 20px !important;
  }

  .sv_q_erbox {
    margin: 0.5em 0 !important;
    padding: 0 !important;
    background-color: inherit !important;
    border: none !important;
    color: ${UiColors.orangeColor} !important;
  }

  .sv_select_wrapper {
    width: 320px !important;
  }

  .sv_select_wrapper:before {
    background-color: var(--dark) !important;
  }

  select:not(.react-time-picker__inputGroup__amPm),
  input[type='text'],
  textarea {
    width: 320px !important;
  }

  select:focus,
  input[type='text']:focus,
  textarea:focus {
    border: 1px solid var(--dark) !important;
  }

  @media screen and ${DEVICES.mobileL} {
    ${Section} > div {
      padding: 20px 5px 20px 5px !important;
    }
  }

  ${Section} {
    flex: 1 0;
  }
`;

export const Dl = styled.dl`
  margin: 0;
  padding: 0;
`;

export const Dt = styled.dt`
  margin: 0 0 10px;
  padding: 0;
  font-size: 16px;
  line-height: 1.17;
  color: var(--dim-grey);
`;

export const Dd = styled.dd`
  font-size: 18px;
  line-height: 1.33;
  color: var(--dark);

  :not(:last-of-type) {
    margin-bottom: 25px;
  }

  a {
    color: var(--curious-blue);
  }
`;
