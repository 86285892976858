/* eslint-disable import/no-cycle */
/* eslint-disable template-curly-spacing */
import styled, { css } from 'styled-components';
// eslint-disable-next-line import/no-cycle
import { DEVICES } from '_constants';
import { UiColors } from 'assets/styles/old/global';
import { modalType } from 'ui-kit/Modal/old/modal.style';
import styles from '_constants/old/_constants/index';

const {
  BUTTON_STYLES: {
    ACCEPT_AUDIT,
    ACCEPT_MODAL_BIG,
    ACCEPT_REPORT_BIG,
    ACCEPT_AGENCY_BIG,
    CANCEL_AGENCY_BIG,
    CANCEL_MODAL,
    CANCEL_MODAL_BIG,
    CANCEL_AUDIT,
    DELETE_MODAL_BIG,
    DELETE_MODAL_SUSPENSION,
    SAFARI_BUTTON_DROP,
    DISCARD,
    RESET,
    SAFARI_BUTTON,
    SAFARI_BUTTON_HEADER,
    RETAILERS_LIST,
    RETAILERS,
    RETAILER_CANCEL,
    CANCEL,
    CANCEL_MERGER,
    ACCEPT_MODAL,
    FILTER,
    SEARCH,
    SUBMIT,
    ADD_RETAILER,
    ACCEPT,
    MERGER_CLOSE_MODAL_END_EDIT,
    CLOSE_FREE_TEXT_FIELDS,
    ADD,
    ACCEPT_REPORT,
    CANCEL_REPORT,
  },
} = styles;

const buttonAdditionalStyles = (type) => {
  switch (type) {
    case ACCEPT:
      return css`
        background-color: ${UiColors.mangoColor};
        color: ${UiColors.forestColor};
        width: 147px;
        margin-left: 7px;
        padding: 10px 0;
      `;
    case 'AcceptProfile':
      return css`
        background-color: ${UiColors.mangoColor};
        color: ${UiColors.forestColor};
        width: 147px;
        margin-left: 7px;
        padding: 10px 0;
        height: calc(100% - 13px);
      `;
    case 'SaveEnforcement':
      return css`
        background-color: ${UiColors.mangoColor};
        color: ${UiColors.forestColor};
        width: 194px;
        height: 40px;
      `;
    case 'LeaveEnforcement':
      return css`
        background-color: ${UiColors.mangoColor};
        color: ${UiColors.forestColor};
        width: 220px;
        height: 40px;
        margin-left: 8px;
      `;
    case 'EditEnforcement':
      return css`
        background-color: ${UiColors.forestColor};
        width: 98px;
        height: 40px;
        &:focus {
          border: 1px solid ${UiColors.focusColor}!important;
          outline-color: ${UiColors.focusColor} !important;
        }
      `;
    case SUBMIT:
      return css`
        background-color: ${UiColors.mangoColor};
        color: ${UiColors.forestColor};
        margin: auto;
        width: 147px;
        padding: 10px 0;
      `;
    case 'SubmitReset':
      return css`
        background-color: ${UiColors.mangoColor};
        color: ${UiColors.forestColor};
        margin: auto;
        width: 147px;
        margin-top: 36px;
        padding: 10px 0;
      `;
    case 'SubmitPass':
      return css`
        background-color: ${UiColors.mangoColor};
        color: ${UiColors.forestColor};
        margin: auto;
        width: 171px;
        padding: 10px 0;
      `;
    case 'Logout':
      return css`
        background-color: ${UiColors.mangoColor};
        color: ${UiColors.forestColor};
        width: 123px;
        padding: 10px 0;
      `;
    case CANCEL:
      return css`
        background-color: ${UiColors.forestColor};
        color: ${UiColors.peachColor};
        width: 123px;
        padding: 10px 0;
      `;
    case CANCEL_REPORT:
      return css`
        background-color: ${UiColors.forestColor};
        color: ${UiColors.peachColor};
        width: 123px;
        padding: 10px 0;
      `;
    case CANCEL_MERGER:
      return css`
        background-color: ${UiColors.forestColor};
        color: ${UiColors.peachColor};
        width: 123px;
        padding: 10px 0;
        @media ${DEVICES.tablet} {
          width: 60px;
          font-size: 11px;
          padding: 3px 0;
        }
      `;
    case 'ProfileAccpet':
      return css`
        background-color: ${UiColors.forestColor};
        color: ${UiColors.peachColor};
        width: 172px;
        padding: 10px 0;
      `;
    case CANCEL_MODAL_BIG:
      return css`
        background-color: ${UiColors.forestColor};
        color: ${UiColors.peachColor};
        width: 166px;
        height: 40px;
        margin-top: ${(merger) => (merger ? '0' : '25px')};
        &:focus {
          border: 1px solid ${UiColors.focusColor}!important;
          outline-color: ${UiColors.focusColor} !important;
        }
      `;

    case DELETE_MODAL_BIG:
      return css`
        background-color: ${UiColors.gray};
        width: 166px;
        height: 40px;
        margin-top: 25px;
      `;
    case CANCEL_MODAL:
      return css`
        background-color: ${UiColors.forestColor};
        color: ${UiColors.peachColor};
        width: 132px !important;
        height: 40px;
        margin-top: 25px;
      `;
    case CANCEL_AUDIT:
      return css`
        background-color: ${UiColors.forestColor};
        color: ${UiColors.peachColor};
        width: 92px !important;
        height: 40px;
        margin-top: 25px;
      `;
    case DELETE_MODAL_SUSPENSION:
      return css`
        background-color: ${UiColors.gray};
        width: 166px;
        height: 40px;
      `;
    case SEARCH:
      return css`
        background-color: ${UiColors.forestColor};
        color: ${UiColors.peachColor};
        width: 70px !important;
        height: 39px;
      `;

    case FILTER:
      return css`
        background-color: ${UiColors.forestColor};
        color: ${UiColors.peachColor};
        width: 103px;
        height: 39px;
        svg {
          margin-right: 3.5px;

          path {
            fill: ${UiColors.footerBackgroundColor};
          }
        }

        @media ${DEVICES.mobileL} {
          width: 80px;
          padding: 0;
          font-size: 14px !important;
        }
      `;
    case ACCEPT_MODAL_BIG:
      return css`
        background-color: ${UiColors.mangoColor};
        color: ${UiColors.forestColor};
        width: 166px;
        height: 40px;
        margin-left: 7px;
        margin-top: ${(merger) => (merger ? '0' : '25px')};
      `;
    case ACCEPT_AGENCY_BIG:
      return css`
        background-color: ${UiColors.mangoColor};
        color: ${UiColors.forestColor};
        width: 166px;
        height: 40px;
        margin-left: 7px;
      `;
    case CANCEL_AGENCY_BIG:
      return css`
        background-color: ${UiColors.forestColor};
        color: ${UiColors.peachColor};
        width: 166px;
        height: 40px;
        &:focus {
          border: 1px solid ${UiColors.focusColor}!important;
          outline-color: ${UiColors.focusColor} !important;
        }
      `;
    case ACCEPT_REPORT_BIG:
      return css`
        background-color: ${UiColors.mangoColor};
        color: ${UiColors.forestColor};
        width: 166px;
        height: 38px;
        margin-left: 10px;
        margin-top: 20px;
      `;
    case ACCEPT_AUDIT:
      return css`
        background-color: ${UiColors.mangoColor};
        color: ${UiColors.forestColor};
        width: 166px;
        height: 40px;
        margin-left: 7px;
        margin-top: 25px;
      `;
    case DISCARD:
      return css`
        background-color: ${UiColors.mangoColor};
        color: ${UiColors.forestColor};
        width: 200px;
        height: 40px;
        margin-left: 7px;
        margin-top: 25px;
      `;
    case ACCEPT_MODAL:
      return css`
        background-color: ${UiColors.mangoColor};
        color: ${UiColors.forestColor};
        width: 132px !important;
        margin-left: 7px;
        height: 40px;
        margin-top: 25px;
      `;
    case ACCEPT_REPORT:
      return css`
        background-color: ${UiColors.mangoColor};
        color: ${UiColors.forestColor};
        width: 132px;
        margin-left: 7px;
        height: 40px;
      `;
    case SAFARI_BUTTON:
      return css`
        background: inherit;
        text-decoration: underline;
        cursor: pointer;
        padding: 0;
        margin: 0;
        display: inline;

        a {
          text-align: left;
          display: inline;
        }
      `;
    case SAFARI_BUTTON_HEADER:
      return css`
        background: ${UiColors.alabasterFillColor};
        margin-top: 17px;
        a {
          color: ${UiColors.forestColor};
          color: ${UiColors.peachColor};
          text-transform: uppercase;
        }
      `;
    case SAFARI_BUTTON_DROP:
      return css`
        background: inherit;
        margin-left: 0;
        display: block;
        a {
          color: ${UiColors.forestColor};
          color: ${UiColors.peachColor};
          text-transform: uppercase;
        }
      `;
    case 'PopUpCancel':
      return css`
        background-color: ${UiColors.forestColor};
        color: ${UiColors.peachColor};
        min-width: 120px;
      `;
    case 'PopUpAccept':
      return css`
        background-color: ${UiColors.mangoColor};
        color: ${UiColors.forestColor};
        margin-left: 15px;
        padding: 10px;
      `;
    case 'filterModalAccept':
      return css`
        background-color: ${UiColors.mangoColor};
        color: ${UiColors.forestColor};
        width: 88px;
        margin-left: 11px;
        padding: 10px;
      `;
    case 'filterModalCancel':
      return css`
        background-color: ${UiColors.forestColor};
        color: ${UiColors.peachColor};
        width: 88px;
        padding: 10px;
      `;
    case RESET:
      return css`
        background-color: ${UiColors.forestColor};
        color: ${UiColors.peachColor};
        padding: 10px;
      `;
    case 'RetailerAccept':
      return css`
        background-color: ${UiColors.mangoColor};
        color: ${UiColors.forestColor};
        margin-left: 15px;
        min-width: 100px;
        padding: 10px;
      `;
    case RETAILER_CANCEL:
      return css`
        background-color: ${UiColors.forestColor};
        color: ${UiColors.peachColor};
        margin-right: 10px;
        padding: 15px;
        min-width: 120px;
        padding: 10px;
        @media ${DEVICES.mobileL} {
          min-width: auto;
        }
      `;
    case RETAILERS:
      return css`
        background-color: ${UiColors.mangoColor};
        color: ${UiColors.forestColor};
        height: 40px;
        width: 130px;
      `;
    case ADD_RETAILER:
      return css`
        background-color: ${UiColors.mangoColor}!
        color: ${UiColors.forestColor};important;
        height: 40px;
        width: 123px;

        @media ${DEVICES.tablet} {
          height: 42px;
          width: 110px;
          margin-top: 10px;
          font-size: 14px !important;
          line-height: 40px !important;
        }
      `;
    case RETAILERS_LIST:
      return css`
        background-color: ${UiColors.mangoColor};
        color: ${UiColors.forestColor};
        height: 40px;
        width: 123px;

        @media ${DEVICES.mobileL} {
          height: 36px;
          width: 110px;
          font-size: 14px !important;
          a {
            line-height: 35px !important;
          }
        }
      `;
    case 'flexibleBlack':
      return css`
        padding: 12px;
        background-color: ${UiColors.forestColor};
        color: ${UiColors.peachColor};
        height: 40px;
      `;
    case 'userCopy':
      return css`
        width: 110px;
        background-color: ${UiColors.forestColor};
        color: ${UiColors.peachColor};
        height: 40px;
      `;
    case 'userEdit':
      return css`
        width: 110px;
        background-color: ${UiColors.mangoColor};
        color: ${UiColors.forestColor};
        height: 40px;
      `;
    case 'userCopyModal':
      return css`
        width: 191px;
        background-color: ${UiColors.mangoColor};
        color: ${UiColors.forestColor};
        height: 40px;
        margin-left: 7px;
        margin-top: 25px;
      `;
    case ADD:
      return css`
        width: auto;
        background-color: ${UiColors.disabledButtonColor};
        height: 34px;
        color: ${UiColors.forestColor};
        color: ${UiColors.peachColor};
        padding: 0 11px;

        img {
          margin-right: 8px;
        }
      `;
    default:
      return css`
        background-color: ${UiColors.forestColor};
        color: ${UiColors.peachColor};
        width: 123px;
        padding: 10px 0;
      `;
  }
};

const buttonGroupAdditionalStyles = (profileButton) => {
  if (profileButton) {
    return css``;
  }
  return css`
    justify-content: start;
  `;
};

const Button = styled.button.attrs(() => ({
  ariaRequired: true,
  ariaPressed: 'false',
}))`
  @media print {
    display: none !important;
  }
  color: ${({ buttonType }) =>
    [SAFARI_BUTTON, SAFARI_BUTTON_DROP, SAFARI_BUTTON_HEADER].includes(buttonType)
      ? `${UiColors.forestColor};`
      : `${UiColors.peachColor};;`};
  ${({ buttonType }) => buttonAdditionalStyles(buttonType)};
  ${({ buttonType }) =>
    ![SAFARI_BUTTON, SAFARI_BUTTON_HEADER].includes(buttonType) &&
    css`
      display: flex;
    `};
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  border-radius: 1px;
  cursor: pointer;
  ${({ buttonType }) =>
    buttonType !== SAFARI_BUTTON_DROP &&
    css`
      & + & {
        margin-left: 11px;
      }
    `}
  border: 0;

  a {
    width: 100%;
    height: 100%;
    ${({ buttonType }) =>
      ![SAFARI_BUTTON, SAFARI_BUTTON_HEADER, SAFARI_BUTTON_DROP].includes(buttonType) &&
      css`
        line-height: 38px;
      `}
    color: ${({ buttonType }) =>
      ![SAFARI_BUTTON, SAFARI_BUTTON_HEADER, SAFARI_BUTTON_DROP].includes(buttonType)
        ? `${UiColors.fillWhiteColor};  text-decoration: none;`
        : `${UiColors.forestColor}; 
        color: ${UiColors.peachColor};;`};

    color: ${({ buttonType }) =>
      [SAFARI_BUTTON].includes(buttonType) && `${UiColors.mainBlueFillColor}; text-decoration: none;`};
    ${({ mode }) =>
      mode === 'licenseEditMobile' &&
      css`
        display: none;
      `};

    ${({ disabled }) =>
      disabled &&
      css`
        pointer-events: none;
        opacity: 0.6;
        user-select: none;
      `};
  }

  @media ${DEVICES.mobileL} {
    ${({ type }) => modalType(type, `width: 136px;`)};
    ${({ type }) =>
      type === MERGER_CLOSE_MODAL_END_EDIT &&
      css`
        font-size: 10px;
        line-height: 10px;
      `};
  }

  @media ${DEVICES.tablet} {
    ${({ isDefault }) =>
      isDefault &&
      css`
        display: none;
      `};

    ${({ mode }) =>
      mode === 'licenseMobile' &&
      css`
        width: 48%;
        margin-left: 0;
        margin-top: 25px;
      `}
    ${({ mode }) =>
      mode === 'licenseEditMobile' &&
      css`
        width: 100%;
        display: flex;
        margin-left: 0;
      `} 
      ${({ mode }) =>
      mode === 'mergerButton' &&
      css`
        margin-top: 0;
      `} 
      ${({ mode }) =>
      mode === 'fullWidthMobile' &&
      css`
        width: 100%;
        font-size: 12px;
      `}
  }

  ${({ buttonType }) =>
    [
      CANCEL,
      CANCEL_REPORT,
      CANCEL_MERGER,
      'ProfileAccpet',
      'PopUpCancel',
      'filterModalCancel',
      'flexibleBlack',
      'userCopy',
      RESET,
      CANCEL_MODAL_BIG,
      CANCEL_MODAL,
      CANCEL_AUDIT,
      FILTER,
      SEARCH,
      CANCEL_AGENCY_BIG,
      RESET,
      RETAILER_CANCEL,
    ].includes(buttonType) &&
    css`
      &:focus {
        border: 1px solid ${UiColors.focusColor}!important;
        outline-color: ${UiColors.focusColor} !important;
      }
    `};
`;

const ButtonContainer = styled.div.attrs(() => ({
  ariaRequired: true,
}))`
  width: 100%;
  background: ${UiColors.fillWhiteColor};
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.09);
  height: 74px;
  align-items: center;
  justify-content: center;
  position: absolute;
  display: flex;
  bottom: -73px;
  left: 50%;
  -webkit-transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%);
  -webkit-transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%);
  -webkit-transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);

  ${({ isMob }) =>
    isMob &&
    css`
      display: none;

      @media ${DEVICES.tablet} {
        display: flex;
        bottom: ${({ profile }) => (profile ? '-100px' : '0')};
        width: 100%;

        padding: 0 25px;
        justify-content: space-between;
        box-sizing: border-box;

        ${Button} {
          height: 40px;
          margin: 0;
          padding: 0 10px;

          &:nth-last-child(n + 2):nth-last-child(-n + 2):first-child {
            margin: 0 5px 0 0;

            & + button {
              margin: 0 0 0 5px;
            }
          }
        }
      }

      @media ${DEVICES.mobileL} {
        padding: 0 10px;
      }
    `}

  ${({ type }) =>
    type === 'popupAgencies' &&
    css`
      display: none;

      @media ${DEVICES.tablet} {
        background: #ffffff;
        box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.19);

        ${Button} {
          width: 100%;
          height: 40px;
          margin-top: 0;
        }
      }

      @media ${DEVICES.mobileL} {
        /* padding: 0 10px; */
      }
    `};

  ${({ isDouble }) =>
    isDouble &&
    css`
      ${Button} {
        width: 100%;
        margin: 0;
        &:nth-last-child(n + 2):nth-last-child(-n + 2):first-child {
          min-width: 48% !important;
          max-width: 48% !important;

          & + button {
            min-width: 48% !important;
            max-width: 48% !important;
          }
        }
      }
    `}

  ${({ isMobileL }) =>
    isMobileL &&
    css`
      @media ${DEVICES.tablet} {
        display: none;
      }
      @media ${DEVICES.mobileL} {
        display: flex;
      }
    `}
`;

const ButtonsGroups = styled.div.attrs(() => ({
  ariaRequired: true,
}))`
  margin-top: ${({ profile }) => (profile ? `10px` : `none`)};
  position: relative;
  bottom: 0;
  left: 0;
  display: flex;
  display: ${({ type }) => type === 'popupAgencies' && 'none'};
  ${({ profileButton }) => buttonGroupAdditionalStyles(profileButton)};
  ${({ type }) =>
    type === CLOSE_FREE_TEXT_FIELDS &&
    css`
      background: ${UiColors.fillWhiteColor};
      box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.19);
      box-sizing: border-box;
      position: absolute;
      padding: 15px 0px 15px 47px;
      width: 100%;
      height: 69px;
    `};

  ${({ type }) =>
    type === 'popupAgencies' &&
    css`
      position: unset;
      background: ${UiColors.fillWhiteColor};
      box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.19);
      box-sizing: border-box;
      padding: 15px 0px 15px 18px;
      width: 100%;
      height: 69px;
      display: ${({ isShow }) => isShow && 'flex'};
      justify-content: center;

      ${Button} {
        margin: 0;
        margin-right: 15px;
        margin-left: 0;
      }

      @media ${DEVICES.mobileL} {
        padding: 10px 0 0px 10px;
        height: 57px;

        ${Button} {
          margin: 0;
          margin-right: 10px;
          margin-left: 0;
          height: 30px;
          font-size: 14px;
        }
      }
    `};

  @media ${DEVICES.mobileL} {
    ${({ isModal }) =>
      isModal &&
      ` @media ${DEVICES.mobileL} {
         width:calc(100% - 26px);
  }`}
    ${({ type }) =>
      modalType(
        type,
        css`
          position: absolute;
          width: 100%;
          box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.09);
          padding: 12px 16px 12px;
          justify-content: space-around;
          box-sizing: border-box;
        `,
      )};

    ${Button} {
      height: 40px;
      ${({ type }) =>
        type === MERGER_CLOSE_MODAL_END_EDIT &&
        css`
          height: 30px;
          margin: 0 10px;
        `};
    }
  }
`;

const DesktopButtonContainer = styled.div`
  @media ${DEVICES.tablet} {
    display: none;
  }
  @media ${DEVICES.mobileL} {
    display: none;
  }
`;

export { Button, ButtonsGroups, ButtonContainer, DesktopButtonContainer };
