import { UiColors } from 'assets/styles/old/global';
import styled, { css } from 'styled-components';
import { DEVICES } from '_constants';

export const Title = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: var(--dark);
  margin: 5px 0 0;

  @media print {
    display: none !important;
  }
`;

export const ChipText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 30px;
  height: inherit;
  vertical-align: middle;
  text-align: center;
  margin-right: 10px;
`;

export const Chip = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  vertical-align: middle;
  text-align: center;
  box-sizing: border-box;
  background: ${UiColors.mangoColor};
  border-radius: 15px;
  font-size: 14px;
  color: ${UiColors.forestColor};
  padding: 0 14px;
  margin: 0 0 10px 12px;
  max-width: 350px;
  line-height: 30px;

  @supports (display: grid) {
    margin: 0 0 10px 12px;
  }

  @media print {
    -webkit-print-color-adjust: exact;
  }
`;

export const DeleteChip = styled.button`
  border: none;
  cursor: pointer;
  margin-left: auto;
  padding: 0;
  background: transparent;
`;

export const ChipListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ToggleShowFilters = styled.button.attrs(() => ({
  ariaRequired: true,
  role: 'button',
}))`
  height: 30px;
  margin: 0 0 10px 12px;
  padding: 5px 10px 5px 13px;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  color: var(--white);
  background: var(--dark);
  border-radius: 15px;
  cursor: pointer;
  border: none;

  &:focus {
    border: 1px solid var(--focus) !important;
    outline-color: var(--focus) !important;
  }

  @media print {
    display: none !important;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`;

export const ClearFilters = styled.button.attrs(() => ({
  ariaRequired: true,
  role: 'button',
}))`
  height: 30px;
  border: none;
  margin: 0 0 10px 12px;
  padding: 5px 10px 5px 13px;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  color: ${UiColors.peachColor};
  background: ${UiColors.forestColor};
  border-radius: 15px;
  cursor: pointer;

  &:focus {
    border: 1px solid var(--focus) !important;
    outline-color: var(--focus) !important;
  }

  @media print {
    display: none !important;
  }
`;

export const HiddenText = styled.span`
  display: none;
`;

export const Wrapper = styled.div`
  border-bottom: ${({ withoutScroll }) => (withoutScroll ? 'none' : '1px solid var(--whisper)')};
  padding: 15px 20px;
  max-width: 100vw;
  box-sizing: border-box;
  height: auto;
  overflow-y: auto;
  position: relative;
  transition: all 0.6s ease;

  max-height: ${({ isShowAll }) => (isShowAll ? '450px' : '90px')};

  min-height: 70px;

  ${({ withoutScroll }) =>
    withoutScroll &&
    css`
      max-height: ${({ isShowAll }) => (isShowAll ? '2000px' : '90px')};
    `}

  @media ${DEVICES.tablet} {
    max-height: ${({ isShowAll }) => (isShowAll ? '130px' : '90px')};

    ${({ withoutScroll }) =>
      withoutScroll &&
      css`
        max-height: ${({ isShowAll }) => (isShowAll ? '500px' : '90px')};
      `}
  }

  @media print {
    max-height: 130px;
  }
`;
